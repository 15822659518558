import './Hero.css'
import React from 'react'
import dog from '../Navbar/Navbar'
import { Typography } from '@mui/material'
import { Fade } from 'react-awesome-reveal'
import vid from '../../images/newcayaccvideo.mp4'
import vid1 from '../../images/vid1.mp4'
import { useState, useEffect } from 'react'
import {Button, Alert} from '@mui/material'
import axios from 'axios'
import heroImg from '../../images/heroImg.jpeg'
import { useNavigate } from 'react-router'
const Hero = () => {
  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Add event listener to handle window resize
    window.addEventListener('resize', handleResize, { passive: true });

    // Call handleResize initially to set the initial state
    handleResize();

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize, { passive: true });
    };
  }, []);
  const [videoLoaded, setVideoLoaded] = useState(false);

  useEffect(() => {
    const videoElement = document.getElementById('video');

    videoElement.addEventListener('loadeddata', handleVideoLoaded);

    return () => {
      videoElement.removeEventListener('loadeddata', handleVideoLoaded);
    };
  }, []);

  const handleVideoLoaded = () => {
    setVideoLoaded(true);
  };

  const [isLive, setIsLive] = useState('');
  const [isApiCalled, setIsApiCalled] = useState(false); 
  const [showAlert, setShowAlert] = useState(false);
  const heroKey = process.env.REACT_APP_HERO_API_KEY
const id = process.env.REACT_APP_CHANNEL_ID
// const id = 'UCpVm7bg6pXKo1Pr6k5kxG9A'


// UCxwaC9XShBmkgr4m7UTiRKQ pastor's channel id
useEffect(() => {
  if (!isApiCalled) {
    const checkLivestream = async () => {
      const options = {
        method: 'GET',
        url: 'https://youtube138.p.rapidapi.com/channel/videos/',
        params: {
          id: 'UCJ5v_MCY6GNUBTO8-D3XoAg',
          filter: 'live_now',
          hl: 'en',
          gl: 'US'
        },
        headers: {
          'X-RapidAPI-Key': '454d980124msh7e03549c7e8dc8ap1f9852jsn514015d4d077',
          'X-RapidAPI-Host': 'youtube138.p.rapidapi.com'
        }
      };
      try {
        const response = await axios.request(options);
        console.log(response.data.contents.length)
        setIsLive(response.data.contents.length > 0);
        console.log(isLive)
        if (isLive === true || isLive === '') {
          setShowAlert(false)
        } else {
          setShowAlert(true)
        }
        console.log(response);  
      } catch (error) {
        console.error(error);
      }
    };

    checkLivestream();
    setIsApiCalled(true)
  }
  
}, [heroKey, id, isApiCalled, isLive]);








  return (
    <React.Fragment>
      <div id='hero_container'>
      {isMobile && videoLoaded ?  (
          <img src={heroImg} alt='hero_image' className='img-fluid' />
        ) 
        : (
          <video id='video'  className='img-fluid' autoPlay loop muted playsInline onEnded={() => dog}>
            <source src={vid} type="video/mp4" />
          </video>
        )
        }
        <div id='centerednew'>
        {videoLoaded && (
          <Fade triggerOnce={true} damping={0.5}>
            <Typography variant={isMobile ? 'h2' : 'h1'} sx={{ color: 'white', display: { xs: 'flex', sm: 'flex', md: 'flex', lg: 'flex', xl: 'flex' } }} className='hero_title_text' style={{ fontFamily: 'Comfortaa' }}>I AM CAYACC</Typography>
          <Typography className="container text-center" variant='h5' sx={{ color: 'white', pb: 3, display: { xs: 'flex', sm: 'flex', md: 'flex', lg: 'flex', xl: 'flex' } }}>LOVING HIM BY SERVING YOU</Typography>
          <Typography className='container text-xl' variant='p' style={{color: 'white', pb: 3,  display: { xs: 'flex', sm: 'flex', md: 'flex', lg: 'flex', xl: 'flex' }}}>Whether you are a lifelong believer or are just starting to explore your faith, we invite you to join us and discover the joy and peace that comes from a life devoted to Christ. Come as you are, and see what God has in store for you!</Typography>
          <Button size='large' variant='contained' href='/sermons' sx={{mt: 5, color: 'white', backgroundColor: '#72286f', ":hover": { bgcolor: 'white', color: 'orange'}, display: { xs: 'flex', sm: 'flex', md: 'flex', lg: 'flex', xl: 'flex' }}}>Watch LiveStream</Button>
          </Fade>
            )} 
        </div>
      </div>
    </React.Fragment>
  )
}

export default Hero;