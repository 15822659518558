import React from 'react'
import { useState, useEffect } from 'react';
import axios from 'axios';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import '../Blog.css'
import parse from 'html-react-parser';

const Read = () => {

    const [APIData, setAPIData] = useState([]);

useEffect(() => {
    axios.get(`https://64a87e25dca581464b85cc2e.mockapi.io/api/blog/fakeData`)
    .then((response) => {
        setAPIData(response.data.reverse());
    })
}, [])



const getData = () => {
    axios.get(`https://64a87e25dca581464b85cc2e.mockapi.io/api/blog/fakeData`)
        .then((getData) => {
             setAPIData(getData.data.reverse());
         })
}

const onDelete = (id) => {
    const confirmed = window.confirm("Are you sure you want to delete?");
  
    if (confirmed) {
        
  axios.delete(`https://64a87e25dca581464b85cc2e.mockapi.io/api/blog/fakeData/${id}`)
.then(() => {
   getData();
})
}
}

  return (
    <div className="blog_">
    <div style={{backgroundColor: 'purple'}} class="header">
    </div>
    <Link to="/blog"><Button sx={{mt: 5, mb:5}} variant='contained' size='large'>Go Back</Button></Link>
    <div id='blog-cards' className='left-column'>
        {APIData.map((data) => {
            const setData = (data) => {
                console.log(data);
                let { id, firstName, lastName, imageUrl} = data;
        localStorage.setItem('ID', id);
        localStorage.setItem('First Name', firstName);
        localStorage.setItem('Last Name', lastName);
        localStorage.setItem('imageUrl', imageUrl);

 
    
             }
            return (
                <>
        <div className='card' style={{ padding: '50px' }}>
            <h2>{data.firstName}</h2>
            {/* <p>{parse(data.lastName)}</p> */}
            <img src={data.imageUrl} alt='' />
            <div className='contain-buttons'>
            <Button variant='contained' size='large' onClick={() => onDelete(data.id)}>Delete</Button>
        <><Link to='/update'><Button variant='contained' size='large' onClick={() => setData(data)}>Edit</Button></Link></>
        </div>

        </div>
        </>
         )})}
         
        </div>
        </div>
           
  )
}

export default Read