import './ChurchFooter.css'
import { Button } from '@mui/material'
import React from 'react'
const ChurchFooter = () => {
    return (
        <div className='church_bg h-fit container-fluid'>
            <div className='container church_outer'>
                <div className='church_inner'>
                <strong>Come As You Are Community Church</strong>
                <br/>
                <p>7910 S. Anthony Blvd. Fort Wayne, IN 46816</p>
                <br/>
                <Button variant='contained' target="_blank" href='https://www.google.com/maps/place/Come+As+You+Are+Community+Church/@41.0117178,-85.1160966,17z/data=!3m1!4b1!4m6!3m5!1s0x8815eff97fd1b719:0xe4c997e582ee06fa!8m2!3d41.0117178!4d-85.1135217!16s%2Fg%2F1tk6qy7w?entry=ttu' sx={{color: 'white', backgroundColor: 'orange', ":hover": {color: 'orange', bgcolor: 'white'}}} className='map_button'>View Map</Button>
            </div>
            <div id='church_footer_social'>
            <a className='booty' href='https://www.facebook.com/CAYACChurch' target='_blank' rel='noreferrer'><i className="fa fa-facebook-square" ></i></a>
            <a href='https://www.instagram.com/cayacchurch/?hl=en' target='_blank' rel='noreferrer'><i className="fa fa-instagram"></i></a>
            <a href='https://www.youtube.com/@apministries' target='_blank' rel='noreferrer'><i className="fa fa-youtube-play"></i></a>
            </div>
            </div>
        </div>
//         <section className='church_bg container-fluid'>
//   <div className='container church_outer'>
//     <div className='church_inner'>
//       <h1>Come As You Are Community Church</h1>
//       <address>7910 S. Anthony Blvd. Fort Wayne, IN 46816</address>
//       <Button variant='contained' target="_blank" href='https://www.google.com/maps/place/Come+As+You+Are+Community+Church/@41.0117178,-85.1160966,17z/data=!3m1!4b1!4m6!3m5!1s0x8815eff97fd1b719:0xe4c997e582ee06fa!8m2!3d41.0117178!4d-85.1135217!16s%2Fg%2F1tk6qy7w?entry=ttu' sx={{color: 'white', backgroundColor: 'orange', ":hover": {color: 'orange', bgcolor: 'white'}}} className='map_button'>View Map</Button>
//     </div>
//     <div id='church_footer_social'>
//       <a className='booty' href='https://www.facebook.com/CAYACChurch' target='_blank' rel='noreferrer'><i className="fa fa-facebook-square" ></i></a>
//       <a href='https://www.instagram.com/cayacchurch/?hl=en' target='_blank' rel='noreferrer'><i className="fa fa-instagram"></i></a>
//       <a href='https://www.youtube.com/@apministries' target='_blank' rel='noreferrer'><i className="fa fa-youtube-play"></i></a>
//     </div>
//   </div>
// </section>

    )
}

export default ChurchFooter;