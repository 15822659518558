import './Ministry.css'
import MinistryHero from '../../components/MinistryHero/MinistryHero'
import React from 'react'
import { Button, TextField,
   FormLabel, FormControl, InputLabel, Select, MenuItem, RadioGroup, FormControlLabel, Radio, Typography } from '@mui/material';
import fitwomen from '../../images/fitwomen.webp'
import fitmen from '../../images/fitmen.webp'
import fitsingles from '../../images/fitsingles.webp'
import fitmarriage from '../../images/fitmarriage.webp'
import fityouth from '../../images/fityouth.webp'
import fitprayer from '../../images/fitprayer.webp'
import splatter from '../../images/splash1.webp'
import main from '../../images/tools.webp'
import security from '../../images/security.webp'
import disciple from '../../images/disciple.webp'
import leadership from '../../images/leadership.webp'
import facilities from '../../images/facilities.webp'
import family from '../../images/family.webp'
import fit from '../../images/cayacc_fit.webp'
import greetors from '../../images/greetors.webp'
import Phone from '../../components/Phone/Phone'
import pastormike from '../../images/events.webp';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ChurchFooter from '../../components/ChurchInfoFooter/ChurchFooter'
import { initializeApp } from "firebase/app";
import { getDatabase, ref, push, set } from "firebase/database";
import { Zoom } from 'react-awesome-reveal'
import { useState } from 'react';
import axios from 'axios';

const Ministry = () => {
  const NewsletterForm = () => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [submitted, setSubmitted] = useState(false);

    const handleEmailChange = (e) => {
      setEmail(e.target.value);
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
      if (email.trim() === '') {
        setError('Please enter a valid email address.');
      } else {
        console.log('done');
        const sendData = async () => {
            try {
                const response = await axios.post('https://connect.pabbly.com/workflow/sendwebhookdata/IjU3NjUwNTZiMDYzNjA0MzE1MjZhNTUzNTUxMzQi_pc',
                 { data: email });
                console.log(response.data);
                setSubmitted(true); // Set submitted state to true
                setError('');
            } catch (error) {
                console.error(error);
            }
        };
        sendData();
        setError('');
      }
    };
  
    return (
      <form onSubmit={handleSubmit} className='mb-8'>
        {submitted ? (
  <div className="flex items-center justify-center">
    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-green-500 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
    </svg>
    <p className="text-green-500">Form submitted successfully!</p>
  </div>
) : (
  <>
    <input
      type="text"
      placeholder="Enter your email..."
      className="mb-8 w-full pl-4 py-4 rounded-lg z-0 focus:shadow focus:outline-none text-black"
      value={email}
      onChange={handleEmailChange}
    />
    <button type="submit" className="w-1/3 py-4 text-white rounded-lg bg-violet-700 md:bg-[#6A65FF]">
      Get Started Now
    </button>
    {error && <p style={{ color: 'red' }}>{error}</p>}
  </>
)}
      </form>
    );
  };



  const [ministry, setMinistry] = React.useState('');
  const [selectedItem, setSelectedItem] = React.useState('');

  const handleResetSelectedItem = () => {
    setSelectedItem(null);
    setMinistry(null);
    setIsVisible(true);
    setTest(false);
  };
  const [value, setValue] = React.useState('Yes');

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleItem = (event) => {
    setMinistry(event.target.value);
    setSelectedItem(event.target.value);
    setIsVisible(true);
    setTest(false);
  }

  const handleItem1 = (event) => {
    setSelectedItem(event.target.value);
    setMinistry(event.target.value);
    // setTest(false);
  }

  const [name, setName] = React.useState('');
  const [number, setNumber] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [skill, setSkill] = React.useState('');
  const [train, setTrain] = React.useState('');
  const [other, setOther] = React.useState('');
  const [isVisible, setIsVisible] = React.useState(true);
  const [submitted, setSubmitted] = React.useState(false);
  const [signUpForm, setSignUp] = React.useState(false);
  const signUp = () => {
    setSignUp(true);
    setIsVisible(false)
  };

  const [isValid, setIsValid] = React.useState(true);

  const [test, setTest] = React.useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    addUser(name, email, number, ministry, skill, value, other);
    // console.log('Form submitted:', { name, number, email, ministry, skill, value, other })
    setSubmitted(true);
    setTest(true);
  };


 const handleInputChange = (event) => {
    const phoneNumber = event.target.value;
    const regex = /^\d{10}$/; // Regex to match a 10-digit phone number

    setNumber(phoneNumber);
    setIsValid(regex.test(phoneNumber));
  };

  const api = process.env.REACT_APP_FIREBASE_API_KEY

  const domain = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN
  
 const data = process.env.REACT_APP_FIREBASE_DATABASE
  
 const projectId =  process.env.REACT_APP_FIREBASE_PROJECTID
  
  const storageBucket = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
  
 const messaging = process.env.REACT_APP_FIREBASE_MESSAGING
  
  const appId = process.env.REACT_APP_FIREBASE_APP_ID
  
  const measurement = process.env.REACT_APP_FIREBASE_MEASUREMENT1
  

const firebaseConfig = {
  apiKey: api,
  authDomain: domain,
  databaseURL: data,
  projectId: projectId,
  storageBucket: storageBucket,
  messagingSenderId: messaging,
  appId: appId,
  measurementId: measurement
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
  const addUser = (name, email, number, train, other, ministry, skill) => {
    const usersRef = ref(database, 'users');
    const newUserRef = push(usersRef);
    set(newUserRef, {
    name,
      email,
      number,
      train, 
      other, 
      ministry, 
      skill
    });
  };



  return (
    <React.Fragment>
      <MinistryHero />
      <div className='h-fit flex items-center justify-center mt-20'>
        <Zoom triggerOnce={true}>
        <Typography variant='h4' className='text-center text-[purple]'>Learn to build Christ-centered relationships and grow deeper in God's word.</Typography>
        </Zoom>
      </div>
      <div className='new_min2' id='min_sect'>
        <img className='img-fluid' src={splatter} alt='' />
        <Zoom cascade triggerOnce={true}>
        <Typography variant='h6' className='text-center' style={{color: 'white'}}>View Our</Typography>
        <Typography variant='h3' className='text-center'style={{color: 'white'}}>FEATURED MINISTRIES</Typography>
        <br/>
        <Typography className='text-center' style={{color: 'white'}}>USE THE SEARCHBAR BELOW TO VIEW OUR MINISTRIES:</Typography>
        </Zoom>
      {/* <div className='lopl2'>
        <ArrowDownwardIcon style={{fontSize: '64px'}} />
        </div> */}
        <br/>


        <div className='container lopl'>
  <FormControl fullWidth>
  <InputLabel id="demo-simple-select-label">Search Ministries... </InputLabel>
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={ministry}
    label="Ministries"
    onChange={handleItem}
    sx={{backgroundColor: 'white'}}
  >
    <MenuItem value={'men'}>Fit Men Ministry Team</MenuItem>
    <MenuItem value={'women'}>Fit Women Ministry Team</MenuItem>
    <MenuItem value={'singles'}>Fit Singles Ministry Team</MenuItem>
    <MenuItem value={'marriages'}>Fit Marriage Ministry Team</MenuItem>
    <MenuItem value={'youth'}>Fit Youth Ministry Team</MenuItem>
    <MenuItem value={'prayer'}>Fit Prayer Ministry Team</MenuItem>
    <MenuItem value={'disciples'}>Fit Evangelism and Discispleship Ministry Team</MenuItem>
    <MenuItem value={'leader'}>Fit Leader Ministry Team</MenuItem>
    <MenuItem value={'facilities'}>Fit Facilities Ministry Team</MenuItem>
    <MenuItem value={'convo'}>Fit Conversation Ministry Team</MenuItem>
    <MenuItem value={'families'}>Fit Families Ministry Team</MenuItem>
    <MenuItem value={'greet'}>Fit Greeters & Ushers Ministry Team</MenuItem>
    <MenuItem value={'security'}>Fit Security Ministry Team</MenuItem>
    <MenuItem value={'main'}>Fit Maintainence Ministry Team</MenuItem>
    <MenuItem value={'events'}>Fit Events Ministry Team</MenuItem>
  </Select>
</FormControl>
      </div>

  {!selectedItem ? 
        <React.Fragment>
        <section className="mt-12 md:mt-24 mx-auto mb-24">
<div className="w-[95%] mx-auto bg-[purple] bg-opacity-80 md:bg-opacity-100 rounded-[50px]">
 <div className="md:w-[70%] xl:w-1/2 mx-auto text-center py-20 mt-12">
   <h1 className="text-3xl md:text-4xl lg:text-5xl mb-4 font-bold text-white">
     Join our CAYACC newsletter!
   </h1>
   <p className="mb-8 mt-4 pt-3 text-white text-base md:text-lg font-normal w-4/5 mx-auto">
     Subscribe to receive sermon guides, upcoming events, and more straight to your inbox!
   </p>
   <NewsletterForm />
 </div>
</div>
</section>

     <br/>
     <br/>
     <br/>
     </React.Fragment>

: 
 <div className='lopl1 container fitter' style={{backgroundColor: 'white', color: 'black'}}>
      
  {selectedItem === 'men' && isVisible && (
    <div class="container text-center" id='men'>
    <div class="col">
      <div class="col" id='ment'>
       <img src={fitmen} className='img-fluid' alt='' />
      </div>
      <br/>
      <div class="col">
        <Typography variant='h4' style={{color: '#1769aa'}}>Fit Men</Typography>
        <Typography variant='p'>A men's ministry that lives fearlessly, intentionally, and transformationally consists of men who aim to strengthen their faith and brotherly relationships. They are fearless in their pursuit of God's calling, unafraid to take risks, and committed to uplifting each other in their spiritual journey. This ministry focuses on creating a culture of authenticity, vulnerability, and courage to inspire personal growth and impact their community positively, ultimately leading men to become strong followers of Jesus Christ.</Typography>
      </div>
      <br/>
      <Button href='https://form.jotform.com/240688085388168' target='_blank' variant='contained'>Sign Up</Button>
    </div>
  </div>
  )}
  {selectedItem === 'women' && isVisible && (
      <div class="container text-center"  id='men'>
      <div class="col">
        <div class="col" id='ment'>
         <img src={fitwomen} className='img-fluid' alt='' />
        </div>
        <br/>
        <div class="col">
          <Typography variant='h4'>Fit Women</Typography>
          <Typography variant='p'>The women's ministry of this church is a powerful force of fearless, intentional, and transformational faith. This group of women is not content to simply go through the motions of attending meetings, hosting events, and serving in the community; instead, they are deeply committed to living out their faith in every aspect of their lives. They are not afraid to take risks and step out of their comfort zones in order to better serve God and others. They approach every task with intentionality, seeking to build relationships, grow in knowledge and understanding of the gospel, and share the love of Jesus with those around them. Through their ministry, they have seen countless lives transformed by the power of God's love and grace. This is a women's ministry that truly embodies what it means to live fearlessly, intentionally, and transformationally.</Typography>
          <br/>
          <br/>
          <Button href='https://form.jotform.com/240688085388168' target='_blank' variant='contained'>Sign Up</Button>
        </div>
      </div>
    </div>
  )}
    {selectedItem === 'youth' && isVisible &&  (
      <div class="container text-center"  id='men'>
      <div class="col">
        <div class="col" id='ment'>
         <img src={fityouth} className='img-fluid' alt='' />
        </div>
        <br/>
        <div class="col">
          <Typography variant='h4'>Fit Youth</Typography>
          <Typography variant='p'>The children's ministry that embodies fearlessness, intentionality, and transformation is one that empowers its young members to become the best versions of themselves. Such a ministry encourages children to embrace their unique talents and gifts, and to use them for the betterment of their community and the world at large. It emphasizes the importance of developing a strong relationship with God and teaches children to trust in Him fearlessly and wholly. By intentionally focusing on spiritual growth and character development, this ministry equips children with the tools they need to transform themselves and others for the glory of God. Its goal is not just to make them good Christians, but to help them become courageous, compassionate, and effective leaders who will make a positive impact on the world for generations to come.</Typography>
          <br/>
          <Button href='https://form.jotform.com/240688085388168' target='_blank' variant='contained'>Sign Up</Button>
        </div>
      </div>
    </div>
  )}
    {selectedItem === 'singles' && isVisible && (
      <div class="container text-center"  id='men'>
      <div class="col">
        <div class="col" id='ment'>
         <img src={fitsingles} className='img-fluid' alt='' />
        </div>
        <br/>
        <div class="col" >
          <Typography variant='h4'>Fit Singles</Typography>
          <Typography variant='p'>Our Singles Ministry is a community of individuals who are unafraid to live fearlessly, intentionally, and transformationally. We believe that these three values are fundamental to living a fulfilling and purposeful life. We strive to create a culture that fosters growth, challenges our beliefs, and encourages us to step out of our comfort zones. Our events and gatherings are geared towards experiencing new things, developing deep and meaningful relationships, and challenging us to take action towards our personal and spiritual growth. We are passionate about living our lives with intentionality and purpose, knowing that each day is an opportunity to make a positive impact on the world around us. Our ministry is open and welcoming to all individuals regardless of their background, gender or race.</Typography>
         <br/>
         <br/>
          <Button href='https://form.jotform.com/240688085388168' target='_blank' variant='contained'>Sign Up</Button>
        </div>
      </div>
    </div>
  )}
    {selectedItem === 'marriages' && isVisible && (
      <div class="container text-center"  id='men'>
      <div class="col">
        <div class="col" id='ment'>
         <img src={fitmarriage} className='img-fluid' alt='' />
        </div>
        <br/>
        <div class="col">
          <Typography variant='h4'>Fit Marriages</Typography>
          <Typography variant='p'>A marriage ministry that lives fearlessly, intentionally, and transformationally is one that ignites a spark in couples' lives, encouraging them to overcome their fears, embrace intentional actions, and experience life-transforming growth together. This ministry faces challenges and uncertainties head-on, fostering an environment where couples can explore and conquer their deepest fears, building strong foundations based on trust and vulnerability. With a focus on intentional actions, this ministry empowers couples to cultivate meaningful connections, investing time and effort into nurturing their relationship and fostering personal growth. Through various resources, workshops, and counseling, this ministry facilitates transformative experiences for couples, guiding them towards a deeper understanding of themselves and their partners. Ultimately, this ministry is committed to helping couples fearlessly pursue their dreams, intentionally build loving and lasting relationships, and experience personal and relational transformations that will positively impact their lives.</Typography>
          <br/>
          <br/>
          <Button href='https://form.jotform.com/240688085388168' target='_blank' variant='contained'>Sign Up</Button>
        </div>
      </div>
    </div>
  )}
    {selectedItem === 'prayer' && isVisible && (
      <div class="container text-center"  id='men'>
      <div class="col">
        <div class="col" id='ment'>
         <img src={fitprayer} className='img-fluid' alt='' />
        </div>
        <br/>
        <div class="col">
          <Typography variant='h4'>Fit Prayer</Typography>
          <Typography variant='p'>A fearless, intentional, and transformational prayer ministry is one that is committed to seeking God's will and purpose for their community and boldly praying for those needs to be met. They lead by example, living out their faith with boldness and courage, trusting in the power of prayer to transform lives. Members of the ministry are intentional in their prayer practices and often organize prayer events and meetings to join together in fervent prayer and worship. Through their commitment to prayer, they experience transformation not only in their own lives but also in the lives of others. This ministry understands that prayer has the power to break chains of bondage and bring healing and hope to brokenness. They are fearless in their pursuit of God's vision for their community, relying on Him for guidance and direction, and trusting in His faithfulness to answer their prayers according to His will.</Typography>
          <br/>
          <br/>
          <Button href='https://form.jotform.com/240688085388168' target='_blank' variant='contained'>Sign Up</Button>
        </div>
      </div>
    </div>
  )}
    {selectedItem === 'disciples' && isVisible && (
      <div class="container text-center"  id='men'>
      <div class="col">
        <div class="col" id='ment'>
         <img src={disciple} className='img-fluid' alt='' />
        </div>
        <br/>
        <div class="col">
          <Typography variant='h4'>Fit Evangelism and Discispleship</Typography>
          <Typography variant='p'>The evangelism and discipleship ministry that lives fearlessly, intentionally, and transformationally is one that is grounded in faith and conviction. Fearlessness is crucial in this ministry because it requires taking risks and stepping out of one's comfort zone to share the gospel message with others. Intentionality is also key, as it involves purposeful actions to seek out those who may not know about Jesus and intentionally build relationships with them. Transformation is the cornerstone of this ministry, as it seeks to bring about genuine change in people's lives by introducing them to the truth and love of Christ. This ministry operates with a sense of urgency, knowing that every person is valuable and has a unique purpose in God's plan. It is characterized by a deep sense of joy and fulfillment as lives are transformed and people are brought into a closer relationship with Jesus.</Typography>
          <br/>
          <br/>
          <Button href='https://form.jotform.com/240688085388168' target='_blank' variant='contained'>Sign Up</Button>
        </div>
      </div>
    </div>
  )}
    {selectedItem === 'leader' && isVisible && (
      <div class="container text-center"  id='men'>
      <div class="col">
        <div class="col" id='ment'>
         <img src={leadership} className='img-fluid' alt='' />
        </div>
        <br/>
        <div class="col">
          <Typography variant='h4'>Fit Leaders</Typography>
          <Typography variant='p'>A leader who lives fearlessly, intentionally, and transformationally is one who trusts in their abilities, while also relying on divine guidance for direction. This leader wakes up each day with a purpose, driven to bring about positive change in their community. They operate without fear, never allowing the fear of failure or rejection to dictate their actions or deter them from pursuing their goals. This leader intentionally seeks out growth opportunities, investing in their personal and professional development, and continuously pushing themselves to become the best version of themselves. They believe in the power of transformation and work tirelessly to create an environment where transformation can occur. Through their unwavering commitment to fearless, intentional, and transformational leadership, they inspire and empower others to do the same, enabling them to become catalysts for positive change in their own lives and the lives of others.</Typography>
          <br/>
          <br/>
          <Button href='https://form.jotform.com/240688085388168' target='_blank' variant='contained'>Sign Up</Button>
        </div>
      </div>
    </div>
  )}
    {selectedItem === 'facilities' && isVisible && (
      <div class="container text-center"  id='men'>
      <div class="col">
        <div class="col" id='ment'>
         <img src={facilities} className='img-fluid' alt='' />
        </div>
        <br/>
        <div class="col">
          <Typography variant='h4'>Fit Facilities</Typography>
          <Typography variant="p">A facilities ministry that lives fearlessly, intentionally, and transformationally is one that is committed to serving and enriching its community of believers. It is a ministry that is not afraid to take risks, embrace challenges, and step out of its comfort zone in order to make a positive difference. It is intentional in its efforts to create a welcoming and inclusive atmosphere within its facilities, offering a space that is conducive to spiritual growth and development. Moreover, this type of ministry strives to enact transformational change both within its own walls and in the lives of those it encounters through its mission and outreach. By boldly and compassionately addressing the needs of its members, this ministry empowers individuals to become catalysts for change in their own lives and in their communities. Through its fearless, intentional, and transformational approach, this facilities ministry becomes a beacon of hope and inspiration for all who encounter it.</Typography>
          <br/>
          <br/>
          <Button href='https://form.jotform.com/240688085388168' target='_blank' variant='contained'>Sign Up</Button>
        </div>
      </div>
    </div>
  )}
    {selectedItem === 'convo' && isVisible && (
      <div class="container text-center"  id='men'>
      <div class="col">
        <div class="col" id='ment'>
         <img src={fit} className='img-fluid' alt='' />
        </div>
        <br/>
        <div class="col">
          <Typography variant='h4'>Fit Conversations</Typography>
          <Typography variant='p'>A fearless, intentional, and transformational conversations ministry lives and breathes the message of Christ through every conversation and interaction. They approach their ministry with a deep and unshakeable faith in the transformative power of God's word and are not afraid to share it with anyone they come into contact with. Their conversations are guided by a strong sense of intentionality, always seeking to connect with others in a meaningful and authentic way. They serve as agents of God's grace and love, and are committed to bringing the transforming power of the gospel into every conversation they have. Through their unwavering commitment to living fearlessly, intentionally, and transformationally, this ministry is able to effectively and powerfully spread God's message of love and hope to all those they encounter.</Typography>
          <br/>
          <br/>
          <Button href='https://form.jotform.com/240688085388168' target='_blank' variant='contained'>Sign Up</Button>
        </div>
      </div>
    </div>
  )}
    {selectedItem === 'families' && isVisible && (
      <div class="container text-center"  id='men'>
      <div class="col">
        <div class="col" id='ment'>
         <img src={family} className='img-fluid' alt='' />
        </div>
        <br/>
        <div class="col" >
          <Typography variant='h4'>Fit Families</Typography>
          <Typography variant='p'>A fearless, intentional, and transformational family ministry is one that seeks to empower families to live out their faith by embodying Christ-like attitudes and behaviors. This ministry is unafraid to tackle tough issues and challenges head-on, and encourages families to face their fears with courage and determination. It is intentional in its efforts to create meaningful experiences and opportunities for families to grow in their faith together, and is committed to building strong relationships and community within the family unit. The ultimate goal of this ministry is transformation; transforming families into disciples of Christ who live out their faith boldly and spread the love of Christ to those around them. Through deepening relationships with each other and with God, this ministry aims to inspire families to be agents of transformation in their communities and beyond.</Typography>
          <Button href='https://form.jotform.com/240688085388168' target='_blank' variant='contained'>Sign Up</Button>
        </div>
      </div>
    </div>
  )}
    {selectedItem === 'greet' && isVisible && (
      <div class="container text-center"  id='men'>
      <div class="col">
        <div class="col" id='ment'>
         <img src={greetors} className='img-fluid' alt='' />
        </div>
        <br/>
        <div class="col">
          <h1>Fit Greeters and Ushers</h1>
          <p>The greeters and ushers ministry is an essential part of any church, and when they are living fearlessly, intentionally, and transformationally, their impact is immeasurable. These men and women welcome every person who walks through the doors of their church with a smile and a warm embrace, making sure that they feel right at home. They are intentional about creating a warm and inclusive environment where everyone feels valued and loved. This ministry is transformational in that they inspire change within the hearts of those they come in contact with. They are fearless in their approach, always stepping out in faith to make a positive difference in the lives of those they encounter. The greeters and ushers ministry is more than just a welcoming committee; they are a beacon of light and hope for all who enter the doors of their church.</p>
          <Button href='https://form.jotform.com/240688085388168' target='_blank' variant='contained'>Sign Up</Button>
        </div>
      </div>
    </div>
  )}
    {selectedItem === 'security' && isVisible && (
      <div class="container text-center"  id='men'>
      <div class="numm1">
        <div class="col" id='ment'>
         <img src={security} className='img-fluid' alt='' />
        </div>
        <br/>
        <div class="col">
          <Typography variant='h4'>Fit Security</Typography>
          <Typography variant='p'>A security ministry that lives fearlessly, intentionally, and transformationally is one that is driven by a deep sense of purpose and passion for protecting people. These individuals are fearless in the face of danger, and they approach each situation with a calm and collected demeanor. They are intentional in their actions, always seeking to make a difference in the lives of those they serve. They are transformational in their approach to security, always thinking outside the box and finding innovative ways to prevent harm and keep people safe. This ministry is fueled by a strong sense of faith and a desire to make the world a better place, and they are dedicated to fulfilling their mission with courage, integrity, and compassion.</Typography>
          <br/>
          <br/>
          <Button href='https://form.jotform.com/240688085388168' target='_blank' variant='contained'>Sign Up</Button>
        </div>
      </div>
    </div>
  )}
    {selectedItem === 'events' && isVisible && (
      <div class="container text-center"  id='men'>
      <div class="col">
        <div class="col" id='ment'>
         <img src={pastormike} className='img-fluid' alt='' />
        </div>
        <br/>
        <div class="col">
          <Typography variant='h4'>Fit Events</Typography>
          <Typography variant='p'>The events ministry is made up of enthusiastic people who are not afraid to take risks and stepping out in faith. They intend to create a comfortable, thoughtful, and caring environment where people can experience a transformation. They believe that events are a chance to share God's love and grace with others, not just for entertainment. The events ministry strives to bring joy, hope, and transformation amidst life's challenges, and they have been able to touch and impact many lives.</Typography>
          <br/>
          <br/>
          <Button href='https://form.jotform.com/240688085388168' target='_blank' variant='contained'>Sign Up</Button>
        </div>
      </div>
    </div>
  )}
    {selectedItem === 'main' && isVisible && (
      <div class="container text-center"  id='men'>
      <div class="col">
        <div class="col" id='ment'>
         <img src={main} className='img-fluid' alt='' />
        </div>
        <br/>
        <div class="col">
          <Typography variant='h4'>Fit Maintainence</Typography>
          <Typography variant='p'>A maintenance ministry that lives fearlessly, intentionally, and transformationally is one that approaches their work with a deep sense of purpose and conviction. They are fearless in their pursuit of excellence, always seeking to go above and beyond what is expected of them. They are intentional in their actions, carefully thinking through each step they take and considering the impact it will have on both themselves and the community they serve. And above all, they seek to bring about real transformation in the lives of those they encounter, working to break down barriers and build up individuals. This kind of maintenance ministry is not content to simply maintain the status quo, but rather is motivated by a deep desire to see positive change happen. They are bold, driven, and passionate about their work, and their efforts have a lasting impact on all those around them.</Typography>
         <br/>
         <br/>
          <Button href='https://form.jotform.com/240688085388168' target='_blank' variant='contained'>Sign Up</Button>
        </div>
      </div>
    </div>
  )}
</div> }
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
    </div>
    </React.Fragment>
  )
}

export default Ministry