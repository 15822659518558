import generateSitemap from "../apis/sitemaputil";

function Sitemap() {
  const routes = [
    { path: '/' },
    { path: '/pastor' },
    { path: '/connect' },
    { path: '/ministries' },
    { path: '/schedule' },
    { path: '/events' },
    { path: '/prayer' },
    { path: '/sermons' },
    { path: '/give' },
    { path: '/blog' },
  ];

  const sitemapXml = generateSitemap(routes);

  return (
    <pre>
      <code>{sitemapXml}</code>
    </pre>
  );
}

export default Sitemap;
