import './Splatter.css'
import splatter from '../../images/splash1.webp'
import React from 'react'
import { Typography, Button } from '@mui/material'
import clock from '../../images/clock.webp'
import thecross from '../../images/thecross.webp'
import livestream from '../../images/livestream.webp'
import { Zoom } from 'react-awesome-reveal'
const Splatter = () => {
  return (
    <React.Fragment>
      <div className='one'>
        <img className='img-fluid' src={splatter} alt='' />
        <div className=" items-center h-fit flex flex-col justify-center">
          <Zoom triggerOnce={true}>
            <div className="flex lg:flex-row flex-col items-center justify-center white text-center items">
              <div className="flex flex-col items-center" style={{ flex: 1 }}>
                <img className='svg_icons' src={clock} alt='' />
                <Typography variant='h5' className='white' style={{ color: 'orange' }}><strong>WORSHIP SERVICES</strong></Typography>
                <Typography variant='p' sx={{ pb: 5, display: { xs: 'flex', sm: 'flex', md: 'flex', lg: 'flex', xl: 'flex' } }} className='text-center white'>JOIN US IN-PERSON OR ONLINE SUNDAY at 10:00 AM EST</Typography>
              </div>
              <div className="flex flex-col items-center" style={{ flex: 1 }}>
                <img className='svg_icons' src={livestream} alt='' />
                <Typography variant='h5' className='' style={{ color: 'orange' }}><strong>WATCH ONLINE</strong></Typography>
                <Typography variant='p' sx={{ pb: 5, display: { xs: 'flex', sm: 'flex', md: 'flex', lg: 'flex', xl: 'flex' } }} className='text-center'>
                  Click the link below to watch past sermons or join us live online.</Typography>
                  <Button size='large' variant='contained' href='/sermons' sx={{ mt: 0, color: 'white', backgroundColor: 'orange', ":hover": { bgcolor: 'white', color: 'orange' } }}>View Past Sermons</Button>
              </div>
              <div className="flex flex-col items-center" style={{ flex: 1 }}>
                <img className='svg_icons' src={thecross} alt='' />
                <Typography variant='h5' className='' style={{ color: 'orange' }}><strong>JOIN US IN PERSON</strong></Typography>
                <Typography variant='p' sx={{ pb: 5, display: { xs: 'flex', sm: 'flex', md: 'flex', lg: 'flex', xl: 'flex' } }} className='text-center'>Join us online or in person! We are located at 7910 S. Anthony Bldv, Fort Wayne, IN 46816</Typography>

              </div>
            </div>
          </Zoom>
        </div>

      </div>
      <div id="two" style={{ backgroundColor: 'purple' }}>
        <img className='img-fluid' src={splatter} alt='' />
      </div>
    </React.Fragment>
  )
}

export default Splatter;