import React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import parse from 'html-react-parser';
import { Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import pastor from '../../images/pastor_blog_image.webp'
import moment from 'moment';
import LoadingPageBlog from '../../components/LoadingPageBlog'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
const SingleBlogPost = ({id}) => {
  const [APIData, setAPIData] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  // Find the specific data based on the id
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://64a87e25dca581464b85cc2e.mockapi.io/api/blog/fakeData/${id}`);
        setAPIData(response.data);
        console.log(response.data)
        setIsLoading(false);
      } catch (error) {
        console.error(error);
      }
    };
  
    fetchData();
  }, [id]);
  
  
 

  return (
    <React.Fragment>
      {isLoading ? <LoadingPageBlog /> :
      <div class="bg-gradient-to-br from-purple-900 to-purple-100/40 h-fit flex items-center justify-center">
        <div className='pt-40 pb-20'>
      {APIData && (
         
          <div className='container blog_padding' style={{}}>
            <div className='flex flex-col text-center items-center justify-center'>
              <Typography variant='h4'>{APIData.firstName}</Typography>
              <Typography variant='h5'>{APIData.subTitle}</Typography>
              <br/>
            <Typography variant='subtitle' sx={{color: 'white'}}>By <span >Pastor Anthony Payton </span></Typography>&nbsp;&nbsp;<span><Avatar src={pastor} alt=''/></span>
            <br/>
            <Typography variant='subtitle' sx={{color: 'white'}}>{moment(APIData.createdAt).format('MM-DD-YYYY')}</Typography>
            </div>
            <br/>
            <br/>
            <div  style={{cursor: 'pointer'}}  >
              <a href='/blogs'>
      <ArrowBackIcon style={{color:'white'}}/>
      <span
        style={{
          marginLeft: '5px',
          fontWeight: 'bold',
        }}
      >
        Back to Blogs
      </span>
      </a>
      </div>
            <br/>
            <br/>
            <Typography variant='p'>{parse(APIData.lastName)}</Typography>
            </div>
        
        )}
</div>
</div>
}


    </React.Fragment>
  );
};

export default SingleBlogPost;
