import './Blog.css';
import React from 'react'
import ChurchFooter from '../../components/ChurchInfoFooter/ChurchFooter';
import CreateBlog from './CRUD/Create';
import { useState, useEffect} from 'react';
import { Button, Typography } from '@mui/material';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { Link } from 'react-router-dom';
import BlogView from './BlogView';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import BlogHero from './BlogHero';
import moment from 'moment';
import LoadingPage from '../../components/LoadingPageBlog';
import BlogCards from './BlogCards';


const Blog = () => {
const [showCreateBlog, setCreateBlog] = useState(false)
const [buttonName, setButtonName] = useState('Create');
const [APIData, setAPIData] = useState([]);
const [selectedCardId, setSelectedCardId] = useState(null);
const [showSingleBlogPost, setShowSingleBlogPost] = useState(false);
const [isLoading, setIsLoading] = useState(true);


useEffect(() => {
  axios.get(`https://64a87e25dca581464b85cc2e.mockapi.io/api/blog/fakeData`)
    .then((response) => {
      setAPIData(response.data.reverse());
      setIsLoading(false);
    })
}, []);

const [showBlogHero, setShowBlogHero] = useState(0);
const handleCardClick = (id) => {
  // Logic to display the specific blog content based on the clicked card's id
  setSelectedCardId(id);
  setShowSingleBlogPost(true);
  console.log(id)
  const overallBlogsDiv = document.getElementById('handles-overall-layout-of-blogs');
  setTimeout(() => {
    overallBlogsDiv.scrollIntoView({ behavior: 'smooth' });
  }, 100);
  setHideButtons(false);
  setShowBlogHero(showBlogHero - 1);
};

const handleCreateClick = () => {
  setCreateBlog(!showCreateBlog);
  setButtonName('Go Back');
};

const handleGoBackClick = () => {
  setCreateBlog(!showCreateBlog);
  setButtonName('Create');
};




const Auth = ({ allowedEmails, children }) => {
  const { isAuthenticated, user } = useAuth0();
  const userEmail = user?.email;

  if (isAuthenticated && allowedEmails.includes(userEmail)) {
    return children;
  }

  return null;
};

const [isHovered, setIsHovered] = React.useState(false);
const handleMouseEnter = () => {
  setIsHovered(true);
};

const handleMouseLeave = () => {
  setIsHovered(false);
};

// to get scroll behavior for blog
const handleScroll = () => {
  setSelectedCardId(null);
  setHideButtons(true);
  // window.scrollTo({top: 0, behavior: 'smooth',block: 'end',
  // inline: 'center'});
  const overallBlogsDiv = document.getElementById('handles-overall-layout-of-blogs');
  setTimeout(() => {
    overallBlogsDiv.scrollIntoView({ behavior: 'smooth' });
  }, 100);
}


//test



  const [hideButtons, setHideButtons] = useState(true);

  return (
    <React.Fragment>
    <div className='blog_ '>

{!showSingleBlogPost && <BlogHero /> }
{ hideButtons && (<div id="buttons-if-user-verified" className=''>
  <Auth allowedEmails={['enriquemgreene@gmail.com', 'endigoddess@gmail.com', 'apministries@gmail.com']}>
    <div className="container centered_buttons">
    <Button variant='contained' size='large' sx={{mb: 5, mt: 5}}  onClick={showCreateBlog ? handleGoBackClick : handleCreateClick} >{buttonName}</Button>
    <Button variant='contained' size='large' sx={{ mb: 5, mt: 5}} href={'/read'} >Edit</Button>
    </div>
    </Auth>
    {showCreateBlog && <CreateBlog />}
    <br/>
    </div>) }
{isLoading ? <LoadingPage /> : 
    <div id="handles-overall-layout-of-blogs">
  {showSingleBlogPost && selectedCardId ? (
    <BlogView id={selectedCardId} />
  ) : (
    <div className='card__container '>
      {APIData ? APIData.map((data) => (
          <Link>
<div class="container text-center">
  <div class="numm1">
  <div key={data.id} onClick={() => handleCardClick(data.id)}  className='col card1'  style={{ padding: '50px', display: 'flex', justifyContent: 'center', backgroundImage: `url(${data.imageUrl})`, backgroundSize: 'cover', backgroundPosition: 'center'}}>
    
  <div className='blog__text' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', color: 'white' }}>
            <Typography variant='h4'>{data.firstName}</Typography>
            <Typography variant='h6'>{data.subTitle}</Typography>
            <br/>
            <h4 >{moment(data.createdAt).format('MM-DD-YYYY')}</h4>
          </div>
  </div>
  </div>
</div>
          </Link>
      )) : null}
    </div>
  )}
</div>}
</div>
    </React.Fragment>
  )
}

export default Blog