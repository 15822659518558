import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import logo1 from '../images/logo1.webp'
export default function CircularIndeterminate() {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', width: '100vw', flexDirection: 'column'  }}>
        <img src={logo1} alt='' />
        <br />
        Cayacc Blogs Loading...
        <br />
      <CircularProgress />
    </Box>
  );
}