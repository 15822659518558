import React from 'react'
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import ReactQuill from 'react-quill';
import ChurchFooter from '../../../components/ChurchInfoFooter/ChurchFooter';

const Update = () => {
    let navigate = useNavigate();
    
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [subTitle, setSubTitle] = useState('');
    const [id, setID] = useState(null);
    const [image, setImage] = useState(null);
    const [imageUrl, setImageUrl] = useState('');

    useEffect(() => {
            setID(localStorage.getItem('ID'))
            setFirstName(localStorage.getItem('First Name'));
            setLastName(localStorage.getItem('Last Name'));
            setImageUrl(localStorage.getItem('imageUrl'));
            setSubTitle(localStorage.getItem('subTitle'));
    }, []);
    console.log(subTitle);

    const updateAPIData = (e) => {
        e.preventDefault();
        axios.put(`https://64a87e25dca581464b85cc2e.mockapi.io/api/blog/fakeData/${id}`, {
            firstName,
             lastName,
             imageUrl,
             subTitle,
        })
        .then(() => {
            navigate('/read');
        });
    };

    const handleImageUpload = (e) => {
      const file = e.target.files[0];
      setImage(file);
    
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageUrl(reader.result);
      };
      reader.readAsDataURL(file);
    };
    

  return (
    <React.Fragment>
    <div className='blog_'>
        <div style={{backgroundColor: 'purple'}} class="header">
    </div>
    <br/>
    <h1 className='text-center' style={{color: 'purple'}}>Update</h1>
    <br/>
    <br/>
    <form className='container'>
      <div className="mb-3">
        <h3>Title:</h3>
        <input value={firstName} onChange={(e) => setFirstName(e.target.value)} type="text" class="form-control"  />
      </div>
      <div className="mb-3">
        <h3>Subtitle:</h3>
        <input value={subTitle} onChange={(e) => setSubTitle(e.target.value)} type="text" class="form-control"  />
      </div>
      <div className="mb-3">
        <h3>Blog Post:</h3>
        <ReactQuill theme='snow' style={{height: '40dvh'}} value={lastName} onChange={(value) => setLastName(value)} type="text" class="form-control"/>
      </div>
      <br/>
      <br/>
      <div className='mb-3'>
      <input type="file" onChange={handleImageUpload} />
  {imageUrl && <img src={imageUrl} alt="Uploaded" />}
  </div>
    </form>
    
    
        </div>
        <br/>
        <br/>
        <br/>
        <div className='centered_buttons'>
        <Button variant='contained' size='large' onClick={updateAPIData} type="submit" >Submit</Button>
        <Link to="/read"><Button variant='contained' size='large'>Cancel</Button></Link>
        </div>
        <br/>
        <br/>
        </React.Fragment>
  )
}

export default Update