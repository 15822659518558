import { motion } from "framer-motion";
import { useState } from "react";
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";
import useMeasure from "react-use-measure";
import fitwomen from '../../images/fitwomen.webp'
import fitmen from '../../images/fitmen.webp'
import fitsingles from '../../images/fitsingles.webp'
import fitmarriage from '../../images/fitmarriage.webp'
import fityouth from '../../images/fityouth.webp'
import fitprayer from '../../images/fitprayer.webp'
const CARD_WIDTH = 350;
const MARGIN = 20;
const CARD_SIZE = CARD_WIDTH + MARGIN;

const BREAKPOINTS = {
  sm: 640,
  lg: 1024,
};

const MinistryCarousel = () => {
  const [ref, { width }] = useMeasure();
  const [offset, setOffset] = useState(0);

  const CARD_BUFFER =
    width > BREAKPOINTS.lg ? 3 : width > BREAKPOINTS.sm ? 2 : 1;

  const CAN_SHIFT_LEFT = offset < 0;

  const CAN_SHIFT_RIGHT =
    Math.abs(offset) < CARD_SIZE * (posts.length - CARD_BUFFER);

  const shiftLeft = () => {
    if (!CAN_SHIFT_LEFT) {
      return;
    }
    setOffset((pv) => (pv += CARD_SIZE));
  };

  const shiftRight = () => {
    if (!CAN_SHIFT_RIGHT) {
      return;
    }
    setOffset((pv) => (pv -= CARD_SIZE));
  };

  return (
    <section className=" py-8" ref={ref}>
      <div className="relative overflow-hidden p-4">
        <div className="mx-auto max-w-6xl">
          <div className="flex items-center justify-between">
            <h2 className="mb-4 text-4xl ">CAYACC Ministries</h2>

            <div className="flex items-center gap-2">
              <button
                className={`rounded-lg border-[1px] border-neutral-400 bg-white text-black p-1.5 text-2xl transition-opacity ${
                  CAN_SHIFT_LEFT ? "" : "opacity-30"
                }`}
                disabled={!CAN_SHIFT_LEFT}
                onClick={shiftLeft}
              >
                <FiArrowLeft />
              </button>
              <button
                className={`rounded-lg border-[1px] border-neutral-400 bg-white text-black p-1.5 text-2xl transition-opacity ${
                  CAN_SHIFT_RIGHT ? "" : "opacity-30"
                }`}
                disabled={!CAN_SHIFT_RIGHT}
                onClick={shiftRight}
              >
                <FiArrowRight />
              </button>
            </div>
          </div>
          <motion.div
            animate={{
              x: offset,
            }}
            transition={{
              ease: "easeInOut",
            }}
            className="flex"
          >
            {posts.map((post) => {
              return <Post key={post.id} {...post} />;
            })}
          </motion.div>
        </div>
      </div>
    </section>
  );
};

const Post = ({ imgUrl, author, title, description }) => {
  return (
    <div
      className="relative shrink-0 cursor-pointer transition-transform hover:-translate-y-1"
      style={{
        width: CARD_WIDTH,
        marginRight: MARGIN,
      }}
    >
        <a href="/ministries" >
      <img
        src={imgUrl}
        className="mb-3 h-[200px] w-full rounded-lg object-cover"
        alt={`${title}`}
      />
      </a>
      <span className="rounded-md border-[1px] border-neutral-500 px-1.5 py-1 text-xs uppercase text-neutral-500">
        {author}
      </span>
      <p className="mt-1.5 text-lg font-medium">{title}</p>
      <p className="text-sm text-neutral-500">{description}</p>
    </div>
  );
};

export default MinistryCarousel;

const posts = [
  {
    id: 1,
    imgUrl: fitmarriage,
    author: "Marriage",
    title: "CAYACC Fit Marriage",
    description:
      "In our Marriage Ministry, we strengthen unions, offering tools for healthy communication, intimacy, and lasting commitment.",
  },
  {
    id: 2,
    imgUrl: fitmen,
    author: "MEn",
    title: "CAYACC Fit Men",
    description:
      "In our Men’s Ministry, we forge bonds of brotherhood, equipping men to lead with integrity, courage, and compassion.",
  },
  {
    id: 3,
    imgUrl: fitwomen,
    author: "women",
    title: "CAYACC Fit Women",
    description:
      "Our Women’s Ministry celebrates the strength, resilience, and grace of women. Together, we grow in faith, support one another, and impact our community.",
  },
  {
    id: 4,
    imgUrl: fityouth,
    author: "youth",
    title: "CAYACC Fit Youth",
    description:
      "Our Youth Ministry ignites faith in the next generation. We create spaces where young hearts encounter Jesus, grow in character, and impact their peers.",
  },
  {
    id: 5,
    imgUrl: fitprayer,
    author: "prayer",
    title: "CAYACC Fit Prayer",
    description:
      "Our Prayer Ministry is the heartbeat of our church. We intercede for needs, celebrate answered prayers, and seek God’s guidance.",
  },
  {
    id: 6,
    imgUrl: fitsingles,
    author: "singles",
    title: "CAYACC Fit Singles",
    description:
      "Our Singles Ministry is a vibrant community where solo hearts find purpose, connection, and joy. We celebrate singleness as a season of growth and service.",
  },
];