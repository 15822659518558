import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SermonsHero from '../../components/SermonsHero/SermonsHero';
import './Sermon.css'
import ChurchFooter from '../../components/ChurchInfoFooter/ChurchFooter';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import moment from 'moment';
import LoadingPage from '../../components/LoadingPage';
import { Bounce, Zoom } from 'react-awesome-reveal';
import {Typography} from '@mui/material';
import { Document, Page } from '@react-pdf/renderer';
import pdfFile from '../../images/Dressed.pdf'
import { Button } from '@mui/material';
import { motion } from "framer-motion";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import useMeasure from "react-use-measure";





// MEASUREMENTS FOR THE CAROUSEL CARDS

const CARD_WIDTH = 350;
const CARD_HEIGHT = 350;
const MARGIN = 20;
const CARD_SIZE = CARD_WIDTH + MARGIN;

const BREAKPOINTS = {
  sm: 640,
  lg: 1024,
};

// END OF CODE




const Sermon = () => {
  const [isApiCalled, setIsApiCalled] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [videoId, setVideoId] = useState('');
  const [title, setTitle] = useState('');
  const [date, setDate] = useState(null);
  const [data, setData] = useState([]);
  const id = process.env.REACT_APP_CHANNEL_ID


  useEffect(() => {
    if (!isApiCalled) {
      const fetchData = async () => {
        setIsLoading(true)
        const options = {
          method: 'GET',
          url: 'https://youtube138.p.rapidapi.com/channel/videos/',
          params: {
            id: id,
            filter: 'streams_latest',
            hl: 'en',
            gl: 'US'
          },
          headers: {
            'X-RapidAPI-Key': '454d980124msh7e03549c7e8dc8ap1f9852jsn514015d4d077',
            'X-RapidAPI-Host': 'youtube138.p.rapidapi.com'
          }
        };
        


        try {
          const response = await axios.request( options);
          console.log(response.data?.contents[0].video.videoId)
          setData(response.data.contents);
          setVideoId(response.data?.contents[0].video.videoId)
          setTitle(response.data?.contents[0].video.title)
          setDate(response.data?.contents[0].video.publishedTimeText)
          setIsLoading(false);
        } catch (error) {
          console.error(error);
        }
      };
      setIsApiCalled(true)
      fetchData();
    }
  }, [isApiCalled, id, data]);

    const handleDownload = () => {
      window.open(pdfFile, '_blank');
    };

 
    const [clickedVideoId, setClickedVideoId] = useState(null);

    React.useEffect(() => {
      console.log('Clicked Video ID:', clickedVideoId);
      console.log('clickedTitle:', title)
      // setVideoId(clickedVideoId); // Update videoId with clickedVideoId
  }, [clickedVideoId, title]);
  

const handleClick = (test) => {
  setVideoId(test);
  const overallBlogsDiv = document.getElementById('diagonal-div');
  setTimeout(() => {
    overallBlogsDiv.scrollIntoView({ behavior: 'smooth' });
  }, 100);
}




  const [ref, { width }] = useMeasure();
  const [offset, setOffset] = useState(0);

  const CARD_BUFFER =
    width > BREAKPOINTS.lg ? 3 : width > BREAKPOINTS.sm ? 2 : 1;

  const CAN_SHIFT_LEFT = offset < 0;

  const CAN_SHIFT_RIGHT =
    Math.abs(offset) < CARD_SIZE * (data.length - CARD_BUFFER);

  const shiftLeft = () => {
    if (!CAN_SHIFT_LEFT) {
      return;
    }
    setOffset((pv) => (pv += CARD_SIZE));
  };

  const shiftRight = () => {
    if (!CAN_SHIFT_RIGHT) {
      return;
    }
    setOffset((pv) => (pv -= CARD_SIZE));
  };







console.log(videoId)
  return (
    <div>
    <React.Fragment>
      <SermonsHero />
      {!isLoading ? (
      <div id='diagonal-div' className="diagonal-div">
        <br/>
        <br/>
<br/>
          <div className='container text-center'>
            <Typography variant='h6' className='text-center'>Watch Our</Typography>
            <Zoom triggerOnce={true}>
            <Typography variant='h3' style={{fontStyle: 'Comfortaa', color: 'purple'}} className='container text-center'><strong>LATEST SERMON</strong></Typography>
            <br/>
            <Typography variant='h5' className='text-center'>Title: {title}</Typography>
            <br/>
            <Typography variant='subtitle' className='text-center'>{date}</Typography>
            </Zoom>
          </div>
          <br/>
<br/>
<div id='idiv' className='idiv'>
        <iframe
          width="1200"
          height="600"
          src={`https://www.youtube.com/embed/${videoId}`}
          title='s'
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          className='z-0'
          onLoad={() => setIsLoading(false)}
        ></iframe>
</div>
<a className='mt-20 flex justify-center' href="https://os5.mycloud.com/public/0b709099-5b12-4688-a649-dfaddb2fe2ae/folders/6fxpoe7kgkl2z45pnz4kmq57?single=true" target='_blank' rel='noreferrer'><Button variant='outlined' sx={{color: 'purple', borderColor:'purple'}} className='text-center'>CLICK HERE FOR SERMON GUIDE</Button></a>
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
        {/* <SermonCarousel data={data} /> */}
        <section className="" ref={ref}>
      <div className="relative overflow-hidden p-4">
        {/* CARDS */}
        <div className="mx-auto max-w-6xl">
          <p className="mb-4 text-2xl font-semibold">
            Recent Sermons
          </p>
          <motion.div
            animate={{
              x: offset,
            }}
            className="flex"
          >
            {data.map((item, idx) => {
            return (
            <React.Fragment>
            <div
              className="relative shrink-0 cursor-pointer rounded-2xl bg-white shadow-md transition-all hover:scale-[1.015] hover:shadow-xl"
              style={{
                width: CARD_WIDTH,
                height: CARD_HEIGHT,
                marginRight: MARGIN,
                backgroundImage: `url(${item.video.thumbnails[3].url})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
              }}
              onClick={() => {
                handleClick(item.video.videoId);
                setTitle(item.video.title);
                setDate(item.video.publishedTimeText);
              }}
            >
              <div className="absolute inset-0 z-20 rounded-2xl bg-gradient-to-b from-black/90 via-black/60 to-black/0 p-6 text-white transition-[backdrop-filter] hover:backdrop-blur-sm">
                <p className="my-2 text-3xl font-bold">{item.video.title}</p>
                <p className="text-lg text-slate-300">{item.video.publishedTimeText}</p>
              </div>
            </div>
            </React.Fragment>)
            })}
          </motion.div>
        </div>

        {/* BUTTONS */}
        <>
          <motion.button
            initial={false}
            animate={{
              x: CAN_SHIFT_LEFT ? "0%" : "-100%",
            }}
            className="absolute left-0 top-[60%] z-30 rounded-r-xl bg-slate-100/30 p-3 pl-2 text-4xl text-white backdrop-blur-sm transition-[padding] hover:pl-3"
            onClick={shiftLeft}
          >
            <FiChevronLeft />
          </motion.button>
          <motion.button
            initial={false}
            animate={{
              x: CAN_SHIFT_RIGHT ? "0%" : "100%",
            }}
            className="absolute right-0 top-[60%] z-30 rounded-l-xl bg-slate-100/30 p-3 pr-2 text-4xl text-white backdrop-blur-sm transition-[padding] hover:pr-3"
            onClick={shiftRight}
          >
            <FiChevronRight />
          </motion.button>
        </>
      </div>
    </section>
        <br/>
        <br/>
      </div>) : (<LoadingPage />)}
    </React.Fragment>
    </div>   
  );
};

export default Sermon;
