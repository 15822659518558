import './Section_Under.css'
import { Button, Typography } from '@mui/material'
import React from 'react'
import promo from '../../images/promo.mp4'
import promo_img from '../../images/promo-img.webp'
import { useState, useEffect } from 'react'
import { Slide, Fade, Bounce, Zoom } from 'react-awesome-reveal'
import prayer1 from '../../images/pra.webp'
import prayer2 from '../../images/pra1.webp'
// import splatter from '../../images/splash1.png'
const SECTION_UNDER = () => {


const [isMobile, setIsMobile] = useState(false);

useEffect(() => {
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 767);
  };

  // Add event listener to handle window resize
  window.addEventListener('resize', handleResize, { passive: true });

  // Call handleResize initially to set the initial state
  handleResize();

  // Clean up the event listener on component unmount
  return () => {
    window.removeEventListener('resize', handleResize, { passive: true });
  };
}, []);
const showVideo = window.innerWidth > 768;

  return (
    <div className="sm:w-4/5 mx-auto text-center mt-20">
      <Slide direction='down' triggerOnce={true}>

    <Typography variant='h6' className='text-center'>Welcome To</Typography>
  <Typography variant='h2' className='text-center' style={{color: 'purple'}}>Come As You Are Community Church</Typography>
  </Slide>
  <br/>
  <br/>

  {!showVideo ? 
  <img className='pro-img' src={promo_img} alt=''/> :  
  ( <div className='promo'>
    <video autoPlay loop muted style={{zIndex: -1}} className='img-fluid'>
      <source src={promo} type='video/mp4' title='lol'/>
    </video>
  </div>)
  }

<Zoom triggerOnce={true}>
<div className="flex lg:flex-row flex-col items-center justify-center text-center">
 <br/>
 <br/>
  <div className="flex-col flex " style={{ flex: 1 }}>
    <Typography variant='h4' style={{ color: 'purple' }}>Our Mission Statement</Typography>
    <Typography variant='p'>"Committed to teaching saved souls, to reach lost souls, by becoming fully devoted followers of Christ."</Typography>
  </div>
  <br/>
  <div className="flex flex-col" style={{ flex: 1 }}>
    <Typography variant='h4' style={{ color: 'purple' }}>Our Blog</Typography>
    <Typography variant='p'>Click the button below to access Pastor Payton's daily journal updates!</Typography>
  </div>
  <br/>
  <div className="flex flex-col" style={{ flex: 1 }}>
    <Typography variant='h4' style={{ color: 'purple' }}>Our Motto</Typography>
    <Typography variant='p'>"I am CAYACC, Loving Him by Serving You!"</Typography>
  </div>
</div>
<Button size='large' variant='contained' href='/blogs' sx={{ mt: 5, color: 'white', backgroundColor: 'orange', ":hover": { bgcolor: 'white', color: 'orange' } }}>View Blogs</Button>
  </Zoom>
</div>
  )
}

export default SECTION_UNDER;