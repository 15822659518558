import ScheduleHero from '../../components/ScheduleHero/ScheduleHero'
import './Schedule.css'
import { InlineWidget } from 'react-calendly'
import React from 'react'
import ChurchFooter from '../../components/ChurchInfoFooter/ChurchFooter'
const Schedule = () => {
    return (
        <React.Fragment>
            <ScheduleHero />
            <div className='diagonal-div1'>
            <InlineWidget url="https://calendly.com/cayaccfit/30min" />
            </div>
        </React.Fragment>
    )
}

export default Schedule