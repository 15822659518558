import React from 'react'
import './Connect.css'
import FormControl from '@mui/material/FormControl';
import { FormLabel, Radio, RadioGroup, FormControlLabel } from '@mui/material';
import ConnectHero from '../../components/ConnectHero/ConnectHero'
import ChurchFooter from '../../components/ChurchInfoFooter/ChurchFooter'

import { useState } from 'react';
import { initializeApp } from "firebase/app";
import { getDatabase, ref, push, set } from "firebase/database";
import { Zoom } from 'react-awesome-reveal';

const api = process.env.REACT_APP_FIREBASE_API_KEY

const domain = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN

const data = process.env.REACT_APP_FIREBASE_DATABASE

const projectId =  process.env.REACT_APP_FIREBASE_PROJECTID

const storageBucket = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET

const messaging = process.env.REACT_APP_FIREBASE_MESSAGING

const appId = process.env.REACT_APP_FIREBASE_APP_ID

const measurement = process.env.REACT_APP_FIREBASE_MEASUREMENT2

const Connect = () => {
  const firebaseConfig = {
    apiKey: api,
    authDomain: domain,
    databaseURL: data,
    projectId: projectId,
    storageBucket: storageBucket,
    messagingSenderId: messaging,
    appId: appId,
    measurementId: measurement
  };
  
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const database = getDatabase(app);
  
  
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [newsletter, setNewsletter] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [newUser, setNewUser] = useState(false);


  const handleSubmit = async(event) => {
    event.preventDefault();
    addUser(firstName, lastName, email, newsletter, newUser);
    console.log('Form submitted:', { firstName, lastName, email, newsletter, newUser });
    setSubmitted(true);
  }

  const addUser = (firstName, lastName, email, newsletter, newUser) => {
    const usersRef = ref(database, 'users');
    const newUserRef = push(usersRef);
    set(newUserRef, {
      firstName,
      lastName,
      email,
      newsletter,
      newUser
    });
  };
  

  return (
    <React.Fragment>
      <ConnectHero />
      <div className='container w-50 c_test'>
      {submitted ? (
        <>

          
          <h2 className='text-center'>Thank you for submitting the form!</h2>
          <div class='check_test'> <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"> <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"/> <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
</svg>
</div>
</>
        ) : (
          <>
          <Zoom triggerOnce={true}>
        <h2>Connect With Church</h2>

          <form onSubmit={handleSubmit}>
          <FormLabel id="demo-radio-buttons-group-label">First Time Visitor?</FormLabel>
  <RadioGroup
    aria-labelledby="demo-radio-buttons-group-label"
    defaultValue="female"
    name="radio-buttons-group"
  >
    <FormControlLabel value="Yes" onChange={(event) => setNewUser(event.target.value)} control={<Radio />} label="Yes" />
    <FormControlLabel value="No" onChange={(event) => setNewUser(event.target.value)} control={<Radio />} label="No" />
  </RadioGroup>
            <div className="form-group">
              <label htmlFor="firstName">First Name</label>
              <input
                type="text"
                className="form-control"
                id="firstName"
                value={firstName}
                onChange={(event) => setFirstName(event.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="lastName">Last Name</label>
              <input
                type="text"
                className="form-control"
                id="lastName"
                value={lastName}
                onChange={(event) => setLastName(event.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                className="form-control"
                id="email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                required
              />
            </div>
            <br/>
            <div className="form-group form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="newsletter"
                checked={newsletter}
                onChange={(event) => setNewsletter(event.target.checked)}
              />
              <label className="form-check-label" htmlFor="newsletter">
                Sign Up for CAYACC Newsletter
              </label>
            </div>
            <br/>
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </form>
          </Zoom>
          </>
        )}
      </div>
    </React.Fragment>
  )
}

export default Connect;