import './ScheduleHero.css'
import book from '../../images/schedulepic.webp'
import { Typography } from '@mui/material'
import React from 'react'
import { useState, useEffect } from 'react'
import { Fade } from 'react-awesome-reveal'
const ScheduleHero = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Add event listener to handle window resize
    window.addEventListener('resize', handleResize, { passive: true });

    // Call handleResize initially to set the initial state
    handleResize();

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize, { passive: true });
    };
  }, []);
  return (
    <React.Fragment>
    <div id='hero_container1' className='overlay-container'>
      <img id='video' className='img-fluid' src={book} alt='' />
      <div id='centered1'>
      <Fade triggerOnce={true}  delay={200} >
        <Typography variant={isMobile ? 'h2' : 'h1'} sx={{ color: 'white', pb: 5, display: { xs: 'flex', sm: 'flex', md: 'flex', lg: 'flex', xl: 'flex' } }} className='hero_title_text' style={{ fontFamily: 'Comfortaa' }}>SCHEDULE MEETING</Typography>
        <Typography variant='p' style={{color: 'white'}}>Welcome to our church's schedule page. Here you will be able to schedule meetings with our Pastor via Zoom or in person. We invite you to join us as we grow in our faith and community together!</Typography>
        </Fade>
      </div>
    </div>
    </React.Fragment>
  )
}

export default ScheduleHero