import './GiveHero.css'
import { Typography, Button } from '@mui/material'
import React from 'react'
import givehero from '../../images/givehero.webp'
import { useState, useEffect } from 'react'
import { Fade } from 'react-awesome-reveal'
const GiveHero = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Add event listener to handle window resize
    window.addEventListener('resize', handleResize, { passive: true });

    // Call handleResize initially to set the initial state
    handleResize();

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize, { passive: true });
    };
  }, []);
  return (
    <React.Fragment>
    <div id='hero_container'>
    <img id='video' className='img-fluid' src={givehero} alt='' />
    <div id='centerednew' className='give_hero_text'>
    <Fade triggerOnce={true}  delay={200} >
      <Typography variant={isMobile ? 'h2' : 'h1'} sx={{ color: 'white', pb: 5, display: { xs: 'flex', sm: 'flex', md: 'flex', lg: 'flex', xl: 'flex' } }} className='hero_title_text title_wrapp' style={{ fontFamily: 'Comfortaa' }} >We Give because God Gave First</Typography>
      <Typography variant='p' sx={{ color: 'white',  display: { xs: 'flex', sm: 'flex', md: 'flex', lg: 'flex', xl: 'flex' } }} className='text-center'>By contributing to what CAYACC is doing in our communities and around the world, we are using what God has given us to share the good news of Jesus by serving others.</Typography>
      <Button href='https://cash.app/$cayacc' target='_blank' variant='contained' sx={{mt:5, display: {xs: 'flex', sm: 'flex', md: 'flex', lg: 'flex', }}} >Give Your Tithe</Button>
      </Fade>
    </div>
  </div>
  </React.Fragment>
  )
}

export default GiveHero;