import './ConnectHero.css'
import connecthero from '../../images/connect.webp'
import React from 'react'
import { Typography } from '@mui/material'
import { useState, useEffect } from 'react'
import { Fade } from 'react-awesome-reveal'
const ConnectHero = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Add event listener to handle window resize
    window.addEventListener('resize', handleResize, { passive: true });

    // Call handleResize initially to set the initial state
    handleResize();

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize, { passive: true });
    };
  }, []);
  return (
    <div id='hero_container1' className='overlay-container'>
      <div className='' >
      <img id='video' className='img-fluid' src={connecthero} alt='' />
      <div id='centerednew' >
      <Fade triggerOnce={true}  delay={200} >
        <Typography variant={isMobile ? 'h2' : 'h1'} sx={{ color: 'white', pb: 5, display: { xs: 'flex', sm: 'flex', md: 'flex', lg: 'flex', xl: 'flex' } }} className='hero_title_text' style={{ fontFamily: 'Comfortaa' }}>CONNECT</Typography>
        <Typography variant='p' sx={{ color: 'white', pb: 5, display: { xs: 'flex', sm: 'flex', md: 'flex', lg: 'flex', xl: 'flex' } }}  style={{color: 'white'}}>"Join our community and connect with fellow believers as we share in meaningful worship services, inspiring messages, and opportunities for growth and service."</Typography>
        </Fade>
      </div>
  
      </div>
    </div>
  )
}

export default ConnectHero;