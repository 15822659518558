import './Pastor.css'
import PastorHero from '../../components/PastorHero/PastorHero'
import React from 'react'
import ChurchFooter from '../../components/ChurchInfoFooter/ChurchFooter'
import pastor from '../../images/pastor_and_firstlady.webp'
import jack from '../../images/jack.webp'
import TabsforPastor from '../../components/TabsforPastor/TabsforPastor'
import { Zoom } from 'react-awesome-reveal'
const Pastor = () => {
  const [isHalfSize, setIsHalfSize] = React.useState(false);

  React.useEffect(() => {
    const handleResize = () => {
      setIsHalfSize(window.innerWidth >= 820);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);




    return (
        <React.Fragment>
            <PastorHero />
<div className="diagonal-div">
<br/>
<br/>
<br/>

<div class="container text-center ">
  <div class="numm1">
    <div className="col pt" id='ment'>
      <Zoom triggerOnce={true}>
    <h1 style={{fontFamily:'Comfortaa'}}>Anthony Payton</h1>
    <h3><em>Lead Pastor</em></h3>
   <p>Pastor Anthony Payton has served as Senior Pastor/Teacher of Come As You Are Community Church in Fort Wayne, Indiana, for 28 years.
Anthony accepted his call to the ministry in 1986. He preached his first sermon at True Love Missionary Baptist Church, under the pastorate of the late Bishop Jesse White.
Led by the Holy Spirit in 1995, Pastor Payton accepted the responsibility of pastoring Greater Come As You Are Missionary Baptist Church. He speaks very candidly of a life of addiction and of his deliverance, and has helped in the planting of churches in the US, as well as Brazil. What Satan once meant to use for evil, God has now converted and uses for the good of His kingdom building here on earth.
Pastor Payton married Sandra Kay in 1986 and are now celebrating 37 years of marriage. They have 2 children, Zachary and Lydia, 3 grandchildren and a host of spiritual children they have and continue to mentor.</p>
</Zoom>
    </div>
    <div class="col">
      <img src={pastor} 
         alt='' className='img-fluid'
         style={{ width: isHalfSize ? '50%' : 'auto', height: 'auto' }}
         />

        </div>
  </div>
</div>
<br/>
<br/>
<br/>
<br/>
<br/>
<div class="container text-center pastor_section">
  <div class="numm1">
  <div class="col pt">
    <Zoom triggerOnce={true}>
    <h1 className='' style={{fontFamily:'Comfortaa'}}>Jack Fitzpatrick III</h1>
   <h3 className=''><em>Assistant Pastor</em></h3>
   </Zoom>
        </div>
    <div className="col">
    <img src={jack} 
         alt='' className='img-fluid'
         style={{ width: isHalfSize ? '50%' : 'auto', height: 'auto' }}
         />
    </div>
  </div>
</div>
<br/>
<br/>
<br/>
<br/>
<br/>
<div className="tabsforpastor">
<TabsforPastor/>
</div>
</div>


        </React.Fragment>
    )
}

export default Pastor;

