import './PrayerHero.css';
import { Typography, Button, Alert } from '@mui/material';
import React from 'react'
import peace from '../../images/prayer.webp'
import { useState, useEffect } from 'react';
import { Fade } from 'react-awesome-reveal';
const PrayerHero = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Add event listener to handle window resize
    window.addEventListener('resize', handleResize, { passive: true });

    // Call handleResize initially to set the initial state
    handleResize();

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize, { passive: true });
    };
  }, []);

  
  return (
    <React.Fragment>
    <div id='hero_container1' className='overlay-container'>
     <img id='videotest' src={peace} alt="" />
      {/* <div id='centerednew'>
        <Typography variant='h1' sx={{ color: 'white', pb: 5, display: { xs: 'flex', sm: 'flex', md: 'flex', lg: 'flex', xl: 'flex' } }} className='hero_title_text' style={{ fontFamily: 'Comfortaa' }}>PRAYER REQUEST</Typography>
        <Typography variant='h4' sx={{ color: 'white', pb: 5, display: { xs: 'flex', sm: 'flex', md: 'flex', lg: 'flex', xl: 'flex' } }} className='text-center'>Join us for Prayer, Weekdays at 6:00am </Typography>
        <Typography variant='h5' sx={{ color: 'white', pb: 5, display: { xs: 'flex', sm: 'flex', md: 'flex', lg: 'flex', xl: 'flex' } }} className='text-center'> Prayer Line: 260.447.6036</Typography>
      </div> */}
          <div id='centerednew'>
          <Fade triggerOnce={true}  delay={200} >
            <Typography variant={isMobile ? 'h2' : 'h1'} sx={{ color: 'white', display: { xs: 'flex', sm: 'flex', md: 'flex', lg: 'flex', xl: 'flex' } }} className='hero_title_text' style={{ fontFamily: 'Comfortaa', wordWrap: 'break-word', maxWidth: '100%', textOverflow: 'ellipsis'}}>PRAYER REQUEST</Typography>
          <Typography variant='p' sx={{ color: 'white', display: { xs: 'flex', sm: 'flex', md: 'flex', lg: 'flex', xl: 'flex' } }}>Philippians 4:6-7 - "Do not be anxious about anything but in every situation, by prayer and petition, with thanksgiving, present your requests to God. And the peace of God which transcends all understanding, will guard your hearts and your minds in Christ Jesus."</Typography>
          </Fade>
        </div>
    </div>
    </React.Fragment>
  )
}

export default PrayerHero;