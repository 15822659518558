import './TabsforPastor.css'
import { Fade, Slide, Zoom } from 'react-awesome-reveal';
import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import mission from '../../images/mission.webp'
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function TabsforPastor() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [isHalfSize, setIsHalfSize] = React.useState(false);

  React.useEffect(() => {
    const handleResize = () => {
      setIsHalfSize(window.innerWidth >= 820);
    };

    // Add event listener to handle window resize
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Box sx={{ width: '100%' }}>
      <Box className="tabsforpastor" sx={{ borderBottom: 1, borderColor: 'divider', color: 'purple' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" TabIndicatorProps={{ style: { background: "purple", color: 'white' } }} sx={{ '& .MuiTab-root': { fontSize: '1.2rem', padding: '1rem' } }}>
          <Tab label="Our Mission Statement" {...a11yProps(0)}  sx={{ color: value === 0 ? 'orange' : 'inherit' }}/>
          <Tab label="Pastoral Vision" {...a11yProps(1)} sx={{color: value === 1 ? 'orange' : 'inherit' }}/>
        </Tabs>
      </Box>
      <TabPanel value={value} index={0} sx={{borderColor: 'orange', color: 'orange'}}>
        <img className={`image-container container img-fluid ${isHalfSize ? 'half-size' : ''}`} style={{width:'100%', maxHeight: '500px'}} src={mission} alt='' />
      <h3 style={{padding: isHalfSize ? '100px' : '35px' }}>"Committed to teaching saved souls to reach lost souls by becoming fully devoted followers of Christ."</h3>
      </TabPanel>
      <TabPanel value={value} index={1}>
      <h3>12 Dimensions of Our Church Family</h3>
          <br/>
          <Zoom cascade triggerOnce={true}>
          <ol>
            <li><strong>1.</strong> A <strong>Takeover</strong> church family. We believe God wants our church family to be a model for holistic ministry in this city. Therefore, the purpose that us is the mindset that says, "Take over this city for the glory of God!"</li>
            <br/>
            <li><strong>2.</strong> A <strong>Teaching</strong> church family. We believe we are to be a teaching ministry. Jesus is spoken more as a teacher than a preacher or prophet.</li>
            <br></br>
            <li><strong>3.</strong> A <strong>Training</strong> church family. We are a professional training church. Our training programs will impact every aspect of a person's life.</li>
            <br/>
            <li><strong>4.</strong> A <strong>Touching</strong> church. We are here to touch peoples' lives. We will touch across all lines: the black, the white, the poor, the rich, the confused, everyone. We will touch them with love, acceptance, help, hope, forgiveness, guidance, and encouragement of our blessed Savior.</li>
            <br></br>
            <li><strong>5.</strong> A <strong>Transplanting</strong> church family. We will, through God's help, raise up leaders. These leaders will be transplanted in ministry positions all over this world.</li>
            <br/>
            <li><strong>6.</strong> A <strong>Today and Tomorrow</strong> church family. We are students of the times: studying the todays and anticipating the tomorrows for their meanings, and seeking Master Jesus for what we are to do and how we are to minister.</li>
            <br/>
            <li><strong>7.</strong> A <strong>Tremendous</strong> church family. This growth will be seen in both our spiritual nature and physical numbers.</li>
            <br/>
            <li><strong>8.</strong> A <strong>Truthful</strong> church family. Truth will have its place in both our public and private lives as a church family. We will live by the truth of our God.</li>
            <br/>
            <li><strong>9.</strong> A <strong>Togetherness</strong> church family. We are a team because of what we are together in Christ. "Great discoveries and achievements invariably involve the cooperation of many minds" (Alexander Graham Bell).</li>
            <br/>
            <li><strong>10.</strong> A <strong>Trusting</strong> church family. We have full trust in our God. We trust Him in our planning, planting, and pruning.</li>
            <br/>
            <li><strong>11.</strong> A <strong>Thankful</strong> church family. We are a family full of thanks to our God for all that He has done and has decided to do in and through us for His glory.</li>
            <br/>
            <li><strong>12.</strong> A <strong>Tenacity</strong> church family. We are committed in our love for Him and our service to Him.</li>
          </ol>
          </Zoom>
      </TabPanel>
    </Box>
  );
}