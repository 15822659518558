import './PastorHero.css'
import { Typography } from '@mui/material'
import React from 'react'
import book from '../../images/pastorvid.mp4'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { useState, useEffect } from 'react';
import { Fade } from 'react-awesome-reveal';
import heroImg from '../../images/heroImg.jpeg'
const PastorHero = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Add event listener to handle window resize
    window.addEventListener('resize', handleResize, { passive: true });

    // Call handleResize initially to set the initial state
    handleResize();

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize, { passive: true });
    };
  }, []);

  return (
    <React.Fragment>
    <div id='hero_container'>
    {isMobile ? (
          <img src={heroImg} alt='' className='img-fluid' />
        ) : (
          <video id='video' className='img-fluid' autoPlay loop muted playsInline>
            <source src={book} type="video/mp4" />
          </video>
        )}
      <div id='centered1'>
      <Fade triggerOnce={true}  delay={500} >
        <Typography  variant={isMobile ? 'h2' : 'h1'} sx={{ color: 'white', pb: 5, display: { xs: 'flex', sm: 'flex', md: 'flex', lg: 'flex', xl: 'flex' } }} className='hero_title_text' style={{ fontFamily: 'Comfortaa' }}>ABOUT US</Typography>
        <Typography variant='p' style={{color: 'white'}}>Welcome to our church! We are a loving community of believers dedicated to serving God, growing in faith, and spreading the message of hope and salvation to all who seek it. </Typography>
        <br />
        <br />
        <div className='lopl2' style={{color: 'white'}}>
        <ArrowDownwardIcon style={{fontSize: '64px'}} />
        </div>
        </Fade>
      </div>
    </div>
    </React.Fragment>
  )
}

export default PastorHero