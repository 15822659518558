import './SermonsHero.css'
import React from 'react'
import { Typography } from '@mui/material'
import book from '../../images/serm.mp4'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { useState, useEffect } from 'react';
import { Fade } from 'react-awesome-reveal';
import heroImg from '../../images/heroImg.jpeg'
const SermonsHero = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Add event listener to handle window resize
    window.addEventListener('resize', handleResize, { passive: true });

    // Call handleResize initially to set the initial state
    handleResize();

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize, { passive: true });
    };
  }, []);
  return (
    <React.Fragment>
    <div id='hero_container1' className='overlay-container'>
    {isMobile ? (
          <img src={heroImg} alt='' className='img-fluid' />
        ) : (
          <video id='video' autoPlay loop muted playsInline preload='auto'>
            <source src={book} type="video/mp4" />
          </video>
        )}
      <div id='centered1'>
      <Fade triggerOnce={true}  delay={200} >
        <Typography variant={isMobile ? 'h2' : 'h1'} sx={{ color: 'white', pb: 5, display: { xs: 'flex', sm: 'flex', md: 'flex', lg: 'flex', xl: 'flex' } }} className='hero_title_text' style={{ fontFamily: 'Comfortaa' }}>SERMONS</Typography>
        <Typography className='text-xl' variant='p' sx={{ color: 'white', pb: 5, display: { xs: 'flex', sm: 'flex', md: 'flex', lg: 'flex', xl: 'flex' } }} >"And he said to them, 'Go into all the world and proclaim the gospel to the whole creation.'" - Mark 16:15</Typography>
        </Fade>
        <div className='lopl2' style={{color: 'white'}}>
        <ArrowDownwardIcon style={{fontSize: '64px'}} />
        </div>
      </div>
    </div>
    </React.Fragment>
  )
}

export default SermonsHero