import './LatestSermons.css'
import { useState, useEffect } from 'react';
import React from 'react'
import axios from 'axios';
import { Button, Typography} from '@mui/material';
import chain from '../../images/chain.webp'
import crucial from '../../images/cayacc-overflow-series.webp'
import rays from '../../images/rays.webp'
import {Rotate, Zoom } from 'react-awesome-reveal';
import APIS from '../../apis/APIS';
import pdfFile from '../../images/Dressed.pdf'
const LatestSermons = () => {  


  const [videoId, setVideoId] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [title, setTitle] = useState('');
  const [date, setDate] = useState('');
  const [isApiCalled, setIsApiCalled] = useState(false); 
  const id = process.env.REACT_APP_CHANNEL_ID

  
  useEffect(() => {
      const fetchData = async () => {
        const options = {
          method: 'GET',
          url: 'https://youtube138.p.rapidapi.com/channel/videos/',
          params: {
            id: id,
            filter: 'streams_latest',
            hl: 'en',
            gl: 'US'
          },
          headers: {
            'X-RapidAPI-Key': '454d980124msh7e03549c7e8dc8ap1f9852jsn514015d4d077',
            'X-RapidAPI-Host': 'youtube138.p.rapidapi.com'
          }
        };
        try {
          const response = await axios.request(options);
          console.log(response.data?.contents[0].video.videoId)
          setVideoId(response.data?.contents[0].video.videoId)
          setTitle(response.data?.contents[0].video.title)
          setDate(response.data?.contents[0].video.publishedTimeText)
        } catch (error) {
          console.error(error);
        }
      };
      setIsApiCalled(true)
      fetchData();
    }, [isApiCalled, id]);

    // const handleDownload = () => {
    //   window.open(pdfFile, '_blank');
    // };

console.log(videoId);
return (
<div className="container h-fit text-center mt-20 mb-20">
    <div>
<Typography variant='h5'>Watch Our</Typography>
        <Zoom triggerOnce={true}><Typography variant='h3' style={{color: "purple"}}>LATEST SERMON</Typography></Zoom>
       <br/>
       <Typography variant='h5'>{title}</Typography>
       <br/>
       <Typography variant='subtitle'>{date}</Typography>
        </div>
        <br/>
<div className="row">
  <div className="col">
    <div className="idiv">
      {videoId && (
    <iframe
          width="1200"
          height="600"
          src={`https://www.youtube.com/embed/${videoId}`}
          title='s'
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          className='z-0'
        ></iframe> )}
        </div>
  </div>
  <a className='mt-20' href="https://os5.mycloud.com/public/0b709099-5b12-4688-a649-dfaddb2fe2ae/folders/6fxpoe7kgkl2z45pnz4kmq57?single=true" target='_blank' rel='noreferrer'><Button variant='outlined' sx={{color: 'purple', borderColor:'purple'}} className='text-center'>CLICK HERE FOR SERMON GUIDE</Button></a>
  <a className='mt-10' href="/sermons"><Button variant='contained' sx={{color: 'white', backgroundColor:'purple', ":hover": {color: 'purple', backgroundColor: 'white'}}} className='text-center'>View All Sermons</Button></a>
  </div>
  {/* <div className="">
    <br/>
    <Rotate triggerOnce={true}>
    <h3 style={{color: 'purple'}}>SERMON SERIES:</h3>
    <h5>WAY OF THE CROSS</h5>
    <br/>
    <h3 style={{color: 'purple'}}>SERMON TITLE:</h3>
    <h6>Discipleship</h6>
    <br/>
    <h3 style={{color: 'purple'}}>SPEAKER:</h3>
    <h6>Pastor/Teacher Anthony Payton</h6>
    </Rotate>
    <br/>
    <a className='' href="#/" onClick={handleDownload}><p className='text-center'>CLICK HERE FOR SERMON GUIDE</p></a>
    <br/>
    <Button size='large' variant='contained' href='https://www.youtube.com/playlist?list=PLDta0SiOUSL-FO7T58OBQvfu3xLUXf-bU' target='_blank' sx={{ color: 'white', backgroundColor: 'orange', ":hover": { bgcolor: 'white', color: 'orange'}}}>Watch Current Series</Button>
  </div> */}
  {/* <Zoom cascade={true} triggerOnce={true}>
  <div className="text-center">
  <div className="numm1 new_cards_list">
  <div className="col">
    <a className='shadow' target='_blank' rel='noreferrer' href='https://www.youtube.com/playlist?list=PLDta0SiOUSL8i9I4dmlcmqW9nfhNRAMdM'><img src={crucial} alt='' className='img-thumbnail shadow'/></a>
    <Button size='large' variant='contained' href='https://www.youtube.com/playlist?list=PLDta0SiOUSL8i9I4dmlcmqW9nfhNRAMdM' target='_blank' sx={{ mt: 5, mb: 5, color: 'white', backgroundColor: 'orange', ":hover": { bgcolor: 'white', color: 'orange'}}}>Watch This Series</Button>
    </div>
    <div className="col">
      <a className='shadow' target='_blank' rel='noreferrer' href='https://www.youtube.com/playlist?list=PLDta0SiOUSL9-lqg922wfLbNTUZNRm08U'><img src={chain} alt='' className='img-thumbnail shadow'/></a>
      <Button size='large' variant='contained' href='https://www.youtube.com/playlist?list=PLDta0SiOUSL9-lqg922wfLbNTUZNRm08U' target='_blank' sx={{ mt: 5, mb: 5, color: 'white', backgroundColor: 'orange', ":hover": { bgcolor: 'white', color: 'orange'}}}>Watch This Series</Button>
       </div>
    <div className="col">
    <a className='shadow' target='_blank' rel='noreferrer' href='https://www.youtube.com/playlist?list=PLDta0SiOUSL9KgB2ooOG2DsTwnX3VFAZv'><img src={rays} alt='' className='img-thumbnail shadow'/></a>
    <Button size='large' variant='contained' href='https://www.youtube.com/playlist?list=PLDta0SiOUSL9KgB2ooOG2DsTwnX3VFAZv' target='_blank' sx={{ mt:5, mb: 5, color: 'white', backgroundColor: 'orange', ":hover": { bgcolor: 'white', color: 'orange'}}}>Watch This Series</Button>
    </div>
  </div>
</div>
</Zoom>  */}
</div>
    );
  };
    export default LatestSermons;
  