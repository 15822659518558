import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import logo1 from '../images/logo1.webp'
import { AttentionSeeker} from 'react-awesome-reveal';
export default function CircularIndeterminate() {
  const [currentEffect, setCurrentEffect] = React.useState("bounce");

  const effectOptions = [
    "bounce",
    "headShake",
    "heartBeat",
    "jello",
    "pulse",
    "rubberBand",
    "swing",
    "tada",
  ];

  const getRandomEffect = () => {
    const randomIndex = Math.floor(Math.random() * effectOptions.length);
    return effectOptions[randomIndex];
  };
  

  // React.useEffect(() => {
  //   const randomEffect = effectOptions[Math.floor(Math.random() * effectOptions.length)];
  //   setCurrentEffect(randomEffect);
  // }, []);

  React.useEffect(() => {
    const randomEffect = getRandomEffect();
    setCurrentEffect(randomEffect);
  }, []);
  
  

  
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', width: '100vw', flexDirection: 'column'  }}>
      <AttentionSeeker effect={currentEffect}>
        <img src={logo1} alt='' />
        </AttentionSeeker>
        <br />
      <CircularProgress />
    </Box>
  );
}