import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { Auth0Provider } from '@auth0/auth0-react';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { ImageProvider } from './pages/Blog/ImageContext';
import ErrorBoundary from './components/Error/ErrorBoundary';
import { Analytics } from '@vercel/analytics/react';

const root = ReactDOM.createRoot(document.getElementById('root'));
const clientId = process.env.REACT_APP_AUTHO_CLIENT_ID;
root.render(
  <BrowserRouter>
  <Auth0Provider
    domain="dev-l3m4iw4zf0qa35lb.us.auth0.com"
    clientId={clientId}
    authorizationParams={{
      redirect_uri: window.location.origin + "/blogs",
    }}
  >
   
    <ImageProvider>
      <Analytics />
      <ErrorBoundary>
    <App />
    </ErrorBoundary>
    </ImageProvider>

  </Auth0Provider>
  </BrowserRouter>

);

