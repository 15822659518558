import './NewMinistries.css'
import splatter from '../../images/splash1.webp'
import React from 'react'
import fitwomen from '../../images/fitwomen.webp'
import fitmen from '../../images/fitmen.webp'
import fitsingles from '../../images/fitsingles.webp'
import fitmarriage from '../../images/fitmarriage.webp'
import fityouth from '../../images/fityouth.webp'
import fitprayer from '../../images/fitprayer.webp'

import { Card, CardMedia, Typography } from '@mui/material'
import { Zoom } from 'react-awesome-reveal'
import MinistryCarousel from './MinistryCarousel'
const NewMinistries = () => {
  return (
    <React.Fragment>
    <div className='new_min h-screen'>
        <img className='img-fluid' src={splatter} alt='' />
        <Typography variant='h5' className='text-center pt-20' style={{color: 'white'}}>Check out Our</Typography>
       <Zoom triggerOnce={true}><Typography variant='h3' className='text-center'style={{color: 'orange'}}>FEATURED MINISTRIES</Typography></Zoom>
        <p className='text-center'>WE HAVE A PLACE FOR EVERYONE!<br/><br/> HERE ARE JUST A FEW OF OUR FEATURED MINISTRIES TO CHECK OUT...</p>
        <br/>
<MinistryCarousel />
</div>
        <div id='two' style={{backgroundColor: '#121619'}}>
        <img className='img-fluid' src={splatter} alt='' />  
        </div>
        </React.Fragment>
    
  )
}

export default NewMinistries;