import './Navbar.css'
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import logo1 from '../../images/logo1.webp';
import cayacc from '../../images/cayacc.webp';
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation } from 'react-router';


const Navbar = () => {
  



  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();
const location = useLocation();
  const handleButtonClick = () => {
    if (!isAuthenticated) {    
      loginWithRedirect();
      
    } else {
      logout({ logoutParams: { returnTo: window.location.origin}});
    }
  };


  

  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const [isOpen, setIsOpen] = React.useState(false);

  const [navSize, setnavSize] = React.useState("11rem");

  const [navLogoWidth, setNavLogoWidth] = React.useState("100px");

  const [navLogoHeight, setNavLogoHeight] = React.useState("100px")

  const [navColor, setnavColor] = React.useState("transparent");

  const [menuButtonColor, setmenuButtonColor] = React.useState("white");

  const [navlinkColor, setnavLinkColor] = React.useState("white");

  const [navLogo, setNavLogo] = React.useState(cayacc);


  const listenScrollEvent = () => {
    window.scrollY > 175 ? setnavColor("white") : setnavColor("transparent");
    window.scrollY > 175 ? setmenuButtonColor("black") : setmenuButtonColor("white");
    window.scrollY > 10 ? setnavSize("4rem") : setnavSize("11rem");
    window.scrollY > 175 ? setnavLinkColor("purple") : setnavLinkColor("white");
    window.scrollY > 175 ? setNavLogo(logo1) : setNavLogo(cayacc)
    window.scrollY > 175 ? setNavLogoWidth("125px") : setNavLogoWidth("175px");
    window.scrollY > 175 ? setNavLogoHeight("100px") : setNavLogoHeight("110px");
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };


  React.useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent, { passive: true });
    return () => {
      window.removeEventListener("scroll", listenScrollEvent, { passive: true });
    };
  }, []);





  return (
    <AppBar position="fixed" elevation={0} style={{
      backgroundColor: navColor,
      height: navSize,
      transition: "all 1s",
      alignContent: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      color: setnavLinkColor
    }}
      >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <a href='/'>
            <div className='nav_logo'>
              <img id='logo_sizing' src={navLogo} alt='' style={{ width: navLogoWidth, height: navLogoHeight, objectFit: 'contain' }} />
            </div>
          </a>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, justifyContent: 'flex-end' }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              sx={{color: menuButtonColor }}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'flex', md: 'none' },
              }}
            >

                <ul className="nav menu_flex" role='menu'>
              <li className="nav-item active">
                <a className="nav-link" href="/">HOME</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/pastor">
                  ABOUT US</a>
              </li>

              <li className="nav-item dropdown" 
                >
                <a
                  className="nav-link dropdown-toggle"
                  data-bs-toggle="dropdown"
         
                  aria-expanded={isOpen ? "true" : "false"}
                  aria-current="page"
                  href="/#"
                >
                  GET CONNECTED
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <a className="dropdown-item" href="/connect">
                     I'M NEW
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/ministries">
                      MINISTRIES
                    </a>
                  </li>
                </ul>
              </li>
              <li className="nav-item dropdown">
                    <a className="nav-link" href="/schedule">
                      SCHEDULE MEETING
                    </a>
                  </li>
                  <li className="nav-item dropdown">
                    <a className="nav-link" href="/events">
                      EVENTS
                    </a>
                  </li>
                  <li className="nav-item dropdown">
                    <a className="nav-link" href="/prayer">
                      PRAYER
                    </a>
                  </li>
              <li className="nav-item">
                <a className="nav-link" href="/sermons">SERMONS</a>
              </li>
              <li className="nav-item dropdown">
                <a className="nav-link"  href="/give" >GIVE</a>
              </li>
              <li className="nav-item dropdown">
                <a className="nav-link"  href="/blogs" >BLOGS</a>
              </li>



              {/* Only use the commented code below
              to set up blogs for Pastor.  */}

{/* 
              <li className="nav-item">
                {location.pathname === "/blogs" && !isAuthenticated && (
                <a href='/#' className='nav-link' onClick={handleButtonClick} style={{ color: 'black' }}  >
                   SIGN IN
                   </a>
                )}</li>
                <li className="nav-item">
                {isAuthenticated ? <a href='/blogs' className='nav-link' onClick={handleButtonClick} style={{ color: 'black' }}>
                  SIGN OUT
                </a> : null}
              </li>  */}
              <li className="nav-item">
                <a className="nav-link " href="https://apaytonenterprises.com/welcome/shop/" target='_blank' rel='noreferrer noopenner'>STORE</a>
              </li>
            </ul>
            </Menu>
          </Box>

          {/* links on big screen */}
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'flex-end' }}>
            <ul className="nav justify-content-end" role='menu'>
              <li className="nav-item active">
                <a className="nav-link" style={{ color: navlinkColor }} href="/">HOME</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" style={{ color: navlinkColor }} href="/pastor">
                  ABOUT US</a>
              </li>

             

              <li className="nav-item dropdown" onMouseEnter={handleOpenNavMenu}
                onMouseLeave={handleCloseNavMenu}>
                <a
                  className="nav-link dropdown-toggle"
                  data-bs-toggle="dropdown"
                  style={{ color: navlinkColor }}
                  aria-expanded={isOpen ? "true" : "false"}
                  aria-current="page"
                  href="/#"
                >
                  GET CONNECTED
                </a>
                <ul className="dropdown-menu dropdown-content">
                  <li>
                    <a className="dropdown-item" href="/connect">
                      I'M NEW
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/ministries">
                      MINISTRIES
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/schedule">
                      SCHEDULE MEETING
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/events">
                      EVENTS
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/prayer">
                     PRAYER
                    </a>
                  </li>
                </ul>
              </li>

              <li className="nav-item">
                <a className="nav-link" style={{ color: navlinkColor }} href="/sermons">SERMONS</a>
              </li>
              <li className="nav-item dropdown">
                <a className="nav-link" style={{ color: navlinkColor }} href="/give" >GIVE</a>
              </li>
              <li className="nav-item dropdown">
                <a className="nav-link" style={{ color: navlinkColor }} href="/blogs" >BLOGS</a>
              </li>
              <li className="nav-item">
                <a className="nav-link " style={{ color: navlinkColor }} href="https://apaytonenterprises.com/welcome/shop/" target='_blank' rel='noreferrer noopenner'>STORE</a>
              </li>
              <li className="nav-item">
                {location.pathname === "/blogs" && !isAuthenticated && (
                <a href='/#' className='nav-link' onClick={handleButtonClick} style={{ color: navlinkColor }}  >
                   SIGN IN
                   </a>
                )}
                {isAuthenticated ? <a href='/blogs' className='nav-link' onClick={handleButtonClick} style={{ color: navlinkColor }}>
                  SIGN OUT
                </a> : null}
              </li> 
            </ul>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Navbar;
