import React from 'react';
import { Button } from '@mui/material';
import hmm from '../images/hmm.webp'
const NotFound = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', width: '100vw', flexDirection: 'column'  }}>
      <h1>404 Error - Page Not Found</h1>
      <img className='img-fluid' style={{width: '50%', height: '50%'}} src={hmm} alt='' />
      <p>The current page you have navigated to is not found.</p>
      <p>Click Below to be redirected to the Home Page.</p>
      <Button href='/'>Home</Button>
    </div>
  );
};

export default NotFound;
