import { generatePath } from 'react-router-dom';

const generateSitemap = (routes) => {
  const sitemap = routes
    .filter((route) => route.path !== '*')
    .map((route) => {
      const path = generatePath(route.path);
      return `<url><loc>${path}</loc></url>`;
    })
    .join('');

  return `<?xml version="1.0" encoding="UTF-8"?>
<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
  ${sitemap}
</urlset>`;
};

export default generateSitemap;
