import './Events.css'
import EventsHero from '../../components/EventsHero/EventsHero'
import React from 'react'
import ChurchFooter from '../../components/ChurchInfoFooter/ChurchFooter'

import pic1 from '../../images/pricislla.webp'
import pic3 from '../../images/cy1.webp'
import vid1 from '../../images/Sequence.mp4'
import { useState, useEffect } from 'react'
const Events = () => {

  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
      };
  
      // Add event listener to handle window resize
      window.addEventListener('resize', handleResize);
  
      // Call handleResize initially to set the initial state
      handleResize();
  
      // Clean up the event listener on component unmount
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
    
    return (
        <React.Fragment>
            <EventsHero />
            <br />
            <h1 className='text-center' style={{color: 'purple'}}>Get the Latest News and Events at CAYACC!</h1>
            <br />
            <div className='diagonal-div1'>
            <div className="container text-center">

{/* make a function that changes class below to 'column'
if the viewpoint is below a certain width */}
<div className="numm1">
    <div className="col">
    <div className='img-r'>
        <img
            src={pic3}
            className={isMobile ? 'img-fluid' : 'img-fluid img-r'}
            alt=""
        />
        <br/>
        <h5>Cayacc New Series:</h5>
        <h5>Way Of The Cross</h5>
    </div>
    </div>
    <br/>
    <br/>
    <div className="col">
    <div className='img-r'>
        <img
            src={pic1}
            className={isMobile ? 'img-fluid' : 'img-fluid img-r'}
            alt=""
        />
        <br/>
        <h5>Cayacc Women:</h5>
        <h5>Priscilla Shirer Simulcast</h5>
    </div>
    </div>
</div>
</div>
</div>
           <br/>
            <div className='container'>
       <div className="text-center container">
 <h1 style={{color: 'purple'}}>Latest Announcements</h1>
 <br/>
<div className="vidss">
<video muted controls autoPlay loop> 
        <source src={vid1} type="video/mp4"/>
</video>
</div>
</div>
    </div>
    <br/>
    <br/>
            <br />
        </React.Fragment>
    )
}

export default Events