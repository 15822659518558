import React from 'react'
import logo from '../images/logo1.webp'
const Contact = () => {
  return (
    <section className=" bg-gradient-to-br from-purple-900 to-purple-100/40">
  <div className="py-24 ">
    <div className="mt-20 w-4/5 mx-auto bg-white rounded-xl shadow grid grid-cols-1 lg:grid-cols-2  text-center md:text-left">
      <div className="rounded-3xl">
        <img
          className="object-contain rounded-l-xl h-full w-full"
          src={logo}
          alt=''
        />
      </div>
      <div
        className="flex flex-col space-y-6 rounded-xl bg-white h-full px-12 py-12 text-base text-left"
        style={{
          boxShadow: '0px 8px 40px -2px rgba(6, 12, 59, 0.08)'
        }}
      >
        <h1 className="text-4xl font-semibold text-[#060C3C]">
          CAYACC Contact Info
        </h1>
        <p className="text-[#56687B] text-base pt-2 font-normal">
          Welcome to our contact form! We can also be contacted via <br/> our email: cayacc.fit@gmail.com, <br/>or by phone (260) 447-6036. <br/>Send us a message and we'll be in touch!
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2">
          <div className="w-full inline-block md:pr-4">
            <label
              className="text-[#060C3C] font-medium"
              htmlFor="first_name"
            >
              First Name
            </label>
            <input
              className="text-left bg-white border border-[#B4B6C4] border-solid placeholder-text-[#B4B6C4] text-[#060C3C] text-sm rounded-lg w-full inline-block focus:ring-blue-500 focus:border-blue-500  shadow-none  px-2.5 py-3 mt-[10px]"
              id="text"
              label="first_name"
              placeholder="First Name"
              required
              type="text"
            />
          </div>
          <div className="w-full inline-block mt-6 md:mt-0">
            <label
              className="text-[#060C3C] font-medium"
              htmlFor="last_name"
            >
              Last Name
            </label>
            <input
              className="text-left bg-white border border-[#B4B6C4] border-solid  text-gray-800 placeholder-text-[#B4B6C4] text-[#060C3C] text-sm rounded-lg w-full focus:ring-blue-500 focus:border-blue-500  shadow-none  px-2.5 py-3 mt-[10px]"
              id="last_name"
              name="last_name"
              placeholder="Last Name"
              required
              type="text"
            />
          </div>
        </div>
        <div className="w-full">
          <label
            className="text-[#060C3C] font-medium"
            htmlFor="email_address"
          >
            Email Address
          </label>
          <input
            className="w-full text-left bg-white border border-[#B4B6C4] border-solid  placeholder-text-[#B4B6C4] text-[#060C3C] text-sm rounded-lg  focus:ring-blue-500 focus:border-blue-500 block shadow-none  px-2.5 py-3  mr-2 mt-[10px]"
            id="email"
            name="email_address"
            placeholder="Email"
            required
            type="email"
          />
        </div>
        <div>
          <label
            className="text-[#060C3C] font-medium"
            htmlFor="message"
          >
            Message
          </label>
          <textarea
            className="w-full text-left bg-white border border-[#B4B6C4] border-solid  placeholder-text-[#B4B6C4] text-[#060C3C] text-sm rounded-lg  focus:ring-blue-500 focus:border-blue-500 block shadow-none  px-2.5 py-3  mr-2 mt-[10px]"
            id="message"
            name="message"
            placeholder="Message"
            required
            rows="3"
            type="text"
          />
        </div>
        <button
          className="text-white bg-[#312ECB] hover:bg-blue-800 focus:ring-4 shadow-none hover:shadow-none transition  focus:ring-blue-300 font-medium rounded-lg text-sm px-8 py-4 focus:outline-none  "
          type="submit"
        >
          Submit
        </button>
      </div>
    </div>
  </div>
</section>
  )
}

export default Contact