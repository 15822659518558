import './Home.css';
import Navbar from '../../components/Navbar/Navbar';
import Hero from '../../components/Hero/Hero';
import UpcomingEvents from '../../components/UpcomingEvents/UpcomingEvents';
import ChurchFooter from '../../components/ChurchInfoFooter/ChurchFooter';
import React from 'react'
import Splatter from '../../components/Splatter/Splatter';
import LatestSermons from '../../components/LatestSermons/LatestSermons';
import NewMinistries from '../../components/NewMinistries/NewMinistries';
import SECTION_UNDER from '../../components/Section_Under/Section_Under';

const Home = () => {
    return (
        <React.Fragment>
            <Hero />
            <SECTION_UNDER />
            {/* <HoverSection /> */}
            <Splatter />
             <LatestSermons />
             <NewMinistries />
             <UpcomingEvents />
        </React.Fragment>
    )
}

export default Home;
