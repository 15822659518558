import React from 'react'
import { useState, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Button } from '@mui/material';
import '../Blog.css'
import imageCompression from 'browser-image-compression';
import { Resizer } from 'react-image-file-resizer';
const Create = () => {
  const { user, isAuthenticated } = useAuth0();

 let navigate = useNavigate();


  const [firstName, setFirstName] = useState('');
  const [subTitle, setSubTitle] = useState('');
  const [lastName, setLastName] = useState('');
  const [image, setImage] = useState(null);
const [imageUrl, setImageUrl] = useState(null);

  // Add state for the imported image

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
  
    try {
      const compressedFile = await imageCompression(file, {
        maxSizeMB: 10, // Specify the maximum file size in megabytes
        maxWidthOrHeight: 800, // Specify the maximum width or height of the image
      });
  
      setImage(compressedFile);
  
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageUrl(reader.result);
      };
      reader.readAsDataURL(compressedFile);
    } catch (error) {
      console.error('Error compressing image:', error);
    }
  };
  

  const postData = (e) => {
    e.preventDefault();
    const currentDate = new Date(); // Get the current date
    const formattedDate = currentDate.toISOString(); // Format the date as a string
    axios.post("https://64a87e25dca581464b85cc2e.mockapi.io/api/blog/fakeData", {
    firstName,
    lastName,
    subTitle,
    imageUrl,
    date: formattedDate, // Append the formatted date to the blog data
    user: isAuthenticated ? user.given_name : null, 
    userImage: isAuthenticated ? user.picture : null, 
  })
  .then((response) => {
    if (response.ok) {
      console.log('File uploaded successfully');
    } else {
      console.error('Failed to upload file');
    }
    navigate('/read');
  })
  .catch((error) => {
    console.error('An error occurred while uploading the file:', error);
    alert('Your image exceeds the width and length restrictions. 800px(width) by 800px(length) or less');
  });

    console.log(firstName);
    console.log(lastName);
    console.log(formattedDate)
    console.log(user);
    console.log(subTitle);
    // const imageSize = imageUrl.length;
    // console.log(`Image size: ${imageSize} bytes`);
}





  return (
    <React.Fragment>
<form className='container'>
        <div class="mb-3">
    <h3>Title:</h3>
    <input onChange={(e) => setFirstName(e.target.value)} type="text" class="form-control" value={firstName} />
  </div>
  <div class="mb-3">
    <h3>Subtitle:</h3>
    <input onChange={(e) => setSubTitle(e.target.value)} type="text" class="form-control" value={subTitle} />
  </div>
  <div class="mb-6">
    <h3>Blog Post:</h3>
    <ReactQuill theme='snow' style={{height: '40dvh'}} value={lastName} onChange={(value) => setLastName(value)} type="text" class="form-control"/>
  </div>
  <br/>
  <br/>
  <input accept="image/*" type="file" onChange={e => handleImageUpload(e)} />
  {imageUrl && <img id='view_blogimage' src={imageUrl} alt="Uploaded" width={300} height={300}/>}
  <Button sx={{mt: 5, mb: 5}} variant='contained' onClick={postData} type="submit">Submit Blog</Button>
</form>
</React.Fragment>

  )
}

export default Create