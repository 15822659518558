import GiveHero from '../../components/GiveHero/GiveHero'
import './Give.css'
import ChurchFooter from '../../components/ChurchInfoFooter/ChurchFooter'
import { Button } from '@mui/material'
import React from 'react'
import giveicon1 from '../../images/giveicon1.webp'
import giveicon2 from '../../images/giveicon2.webp'
import giveicon3 from '../../images/giveicon3.webp'

const Modal = ({ onClose }) => {
  return (
    <div className="modal">
      <div className="modal-content">
        <button className="close-button" onClick={onClose}>
          Close
        </button>
        <iframe
        className='iframe1'
          src="https://www.youtube.com/embed/hXeb0q3GDfs"
          title="Tithing Video"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};


const Give = () => {

  const [showModal, setShowModal] = React.useState(false);

  const handleButtonClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };



  return (
    <React.Fragment>
        <GiveHero />
        <div id='beyond_the_tithe'>
  <div class="col full_text text-center">
    <br/>
    <div class="col give_p1">
      <h3>Tithing is more than giving.</h3>
      <p>One of the ways we express our gratitude to God is by giving our tithes and offerings.</p>
      <Button variant="contained" onClick={handleButtonClick}>
        What is Tithing?
      </Button>
      {showModal && <Modal onClose={handleCloseModal} />}
       </div>
    <div class="col give_p2">
    <h1>Beyond the Tithe</h1>
    <br/>
<p>"Beyond the tithe" is anything you give beyond 10%. In Malachi 3:10, God says "Go ahead. I dare you. See if you can out-give me." Beyond the tithe is a chance for you to be extravagantly generous. You can give additional gifts to the tithe fund, or to a specific project like PEACE, Food Pantry, and more. So go ahead, test and experience God's generosity!</p>
  <Button variant='contained' disabled>Opportunities Coming Shortly!</Button>
    </div>
  </div>
        </div>
        <br/>
        <div id=''>
            <h1 className='text-center' style={{fontFamily: 'Comfortaa'}}>Ways To Give</h1>

            <div class="container text-center">
  <div class="numm1">
    <div class="col ways_text">
        <img src={giveicon1} className='give_icon img-fluid' alt=''/>
      <h6 className='p_headers'>Give Online</h6>
    </div>
    <div class="col ways_text">
    <img src={giveicon2} className='give_icon img-fluid' alt=''/> 
      <h6>In Person</h6>
    </div>
  
    <div class="col ways_text">
    <img src={giveicon3} className='give_icon img-fluid' alt=''/>
      <h6>Text To Give</h6>
    </div>
    <div class="col ways_text">
    <img src={giveicon3} className='give_icon img-fluid' alt=''/>
      <h6>By Mail</h6>
    </div>
  </div>
  <div class="numm1">
    <div class="col ways_text">
      <p>If you are interested in a more convenient way to give appreciated assets, i.e. stock, consider giving through a Donor Advised Fund (DAF). For more information about DAF, or for help with Estate planning and setting up your trust, contact giving@cayacc.com</p>
    </div>
  </div>
</div>
        </div>
        </React.Fragment>
  )
}

export default Give