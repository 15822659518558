import './EventsHero.css';
import React from 'react'
import { Typography } from '@mui/material';
import promo from '../../images/events.webp'
import { Fade } from 'react-awesome-reveal';

const EventsHero = () => {
  const [isMobile, setIsMobile] = React.useState(false);

  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Add event listener to handle window resize
    window.addEventListener('resize', handleResize, { passive: true });

    // Call handleResize initially to set the initial state
    handleResize();

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize, { passive: true });
    };
  }, []);
  return (
    <div id='hero_container1' className='overlay-container'>
      {/* <video id='video' autoPlay playsInline loop muted>
        <source src={cayaccnews} type="video/mp4" />
      </video> */}
      <div className=''>
      <img id='video' className='img-fluid' src={promo} alt="" />
      <div id='centerednew' >
      <Fade triggerOnce={true}  delay={200} >
        <Typography variant={isMobile ? 'h2' : 'h1'} sx={{ color: 'white', pb: 5, display: { xs: 'flex', sm: 'flex', md: 'flex', lg: 'flex', xl: 'flex' } }} className='hero_title_text' style={{ fontFamily: 'Comfortaa' }}>CAYACC EVENTS</Typography>
        </Fade>
      </div>
      </div>
      
    </div>
  )
}

export default EventsHero