import './Prayer.css'
import PrayerHero from '../../components/PrayerHero/PrayerHero'
import React, { useState } from 'react'
import ChurchFooter from '../../components/ChurchInfoFooter/ChurchFooter'
import { TextField, Button } from '@mui/material'
import prayer1 from '../../images/pra.webp'
import prayer2 from '../../images/pra1.webp'
import { initializeApp } from "firebase/app";
import { getDatabase, ref, push, set } from "firebase/database";
import { Zoom } from 'react-awesome-reveal'
const Prayer = () => {

  const api = process.env.REACT_APP_FIREBASE_API_KEY

  const domain = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN
  
 const data = process.env.REACT_APP_FIREBASE_DATABASE
  
 const projectId =  process.env.REACT_APP_FIREBASE_PROJECTID
  
  const storageBucket = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
  
 const messaging = process.env.REACT_APP_FIREBASE_MESSAGING
  
  const appId = process.env.REACT_APP_FIREBASE_APP_ID
  
  const measurement = process.env.REACT_APP_FIREBASE_MEASUREMENT
  
  
  
const firebaseConfig = {
  apiKey: api,
  authDomain: domain,
  databaseURL: data,
  projectId: projectId,
  storageBucket: storageBucket,
  messagingSenderId: messaging,
  appId: appId,
  measurementId: measurement,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

    const [name, setName] = useState('');
  const [number, setNumber] = useState('');
  const [email, setEmail] = useState('');
  const [prayer, setPrayer] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    addUser(name, email, number, prayer);
    console.log('Form submitted:', { name, number, email, prayer });
    setSubmitted(true);
  };

  const addUser = (name, email, number, prayer) => {
    const usersRef = ref(database, 'users');
    const newUserRef = push(usersRef);
    set(newUserRef, {
    name,
      email,
      number,
      prayer
    });
  };

  return (
    <React.Fragment>
      <PrayerHero />
      <div className='diagonal-div2'>
      <div className='container c_test'>
      {submitted ? (
        <>
          <h2 className='text-center c_test'>We have received your Prayer Request!</h2>
          <div class='check_test'> <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"> <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"/> <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
</svg>
</div>
</>
        ) : (
        <div className="row hotto">
          <img className='img-fluid' src={prayer1} alt=''/>
          <img className='img-fluid' src={prayer2} alt=''/>
          <Zoom triggerOnce={true}>
          <div class="col">
            <br/>
            <br/>
            <h2 className='text-center' style={{color: 'purple'}}>PRAYER REQUEST FORM</h2>
            <form onSubmit={handleSubmit}>
              <div>
                <div class="col">
                  <TextField onChange={(event) => setEmail(event.target.value)} id="standard-basic" label="Email" variant="standard" required value={email} />
                </div>
                <div class="col">
                  <TextField id="standard-basic" label="Name" variant="standard" required value={name} onChange={(event) => setName(event.target.value)}/>
                </div>
                <div class="col">
                  <TextField id="standard-basic" label="Phone Number" variant="standard" value={number} onChange={(event) => setNumber(event.target.value)} />
                </div>
                <br/>
                *<strong>All prayer requests are kept confidential</strong>{<br/>}
                <br></br>
                <h5>Your Prayer Request *</h5>
                <div class="input-group input-group-large">
                  <textarea class="form-control" aria-label="With textarea" aria-describedby="inputGroup-sizing-lg"  style={{height: "150px"}} required value={prayer} onChange={(event) => setPrayer(event.target.value)}></textarea>
                </div>
                <br/>
                <Button variant='contained' type='submit'>Submit</Button>
              </div>
              <br/>
            </form>
          </div>
          </Zoom>
        </div>)}
      </div>
      </div>
    </React.Fragment>
  )
}

export default Prayer;
