import './UpcomingEvents.css'
import num1 from './../../images/pricislla.webp'
import num3 from './../../images/cy1.webp'
import React from 'react'
import { useState, useEffect } from 'react'
import { Bounce, Zoom } from 'react-awesome-reveal'
import axios from 'axios'
const UpcomingEvents = () => {
    const NewsletterForm = () => {
        const [email, setEmail] = useState('');
        const [error, setError] = useState('');
        const [submitted, setSubmitted] = useState(false);

        const handleEmailChange = (e) => {
          setEmail(e.target.value);
        };
      
        const handleSubmit = (e) => {
          e.preventDefault();
          if (email.trim() === '') {
            setError('Please enter a valid email address.');
          } else {
            console.log('done');
            const sendData = async () => {
                try {
                    const response = await axios.post('https://connect.pabbly.com/workflow/sendwebhookdata/IjU3NjUwNTZiMDYzNjA0MzE1MjZhNTUzNTUxMzQi_pc',
                     { data: email });
                    console.log(response.data);
                    setSubmitted(true); // Set submitted state to true
                    setError('');
                } catch (error) {
                    console.error(error);
                }
            };
            sendData();
            setError('');
          }
        };
      
        return (
          <form onSubmit={handleSubmit} className='mb-8'>
            {submitted ? (
      <div className="flex items-center justify-center">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-green-500 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
        </svg>
        <p className="text-green-500">Form submitted successfully!</p>
      </div>
    ) : (
      <>
        <input
          type="text"
          placeholder="Enter your email..."
          className="mb-8 w-full pl-4 py-4 rounded-lg z-0 focus:shadow focus:outline-none"
          value={email}
          onChange={handleEmailChange}
        />
        <button type="submit" className="w-1/3 py-4 text-white rounded-lg bg-violet-700 md:bg-[#6A65FF]">
          Get Started Now
        </button>
        {error && <p style={{ color: 'red' }}>{error}</p>}
      </>
    )}
          </form>
        );
      };
    // const [isMobile, setIsMobile] = useState(false);
    // useEffect(() => {
    //     const handleResize = () => {
    //       setIsMobile(window.innerWidth <= 768);
    //     };

    //     // Add event listener to handle window resize
    //     window.addEventListener('resize', handleResize, { passive: true });
    
    //     // Call handleResize initially to set the initial state
    //     handleResize();
    
    //     // Clean up the event listener on component unmount
    //     return () => {
    //       window.removeEventListener('resize', handleResize, { passive: true });
    //     };
    //   }, []);

    return (
        <React.Fragment>
           <section className="mt-12 md:mt-24 mx-auto mb-24">
  <div className="w-[95%] mx-auto bg-[purple] bg-opacity-80 md:bg-opacity-100 rounded-[50px]">
    <div className="md:w-[70%] xl:w-1/2 mx-auto text-center py-20 mt-12">
      <h1 className="text-3xl md:text-4xl lg:text-5xl mb-4 font-bold text-white">
        Join our CAYACC newsletter!
      </h1>
      <p className="mb-8 mt-4 pt-3 text-white text-base md:text-lg font-normal w-4/5 mx-auto">
        Subscribe to receive sermon guides, upcoming events, and more straight to your inbox!
      </p>
      <NewsletterForm />
      {/* <div className="flex justify-center items-center mb-4 w-full">
  <div className="relative md:w-full lg:w-4/5 mx-auto flex items-center gap-4">
    <input
      className="w-full pl-4 py-4 rounded-lg z-0 focus:shadow focus:outline-none"
      placeholder="Enter your email..."
      type="text"
    />
      <button className=" w-1/3 py-4 text-white rounded-lg bg-violet-700 md:bg-[#6A65FF] ">
        Get Started Now
      </button>
  </div>
</div> */}

      {/* <span className="text-white pt-4 text-sm md:text-base">
        * Unsubscribe any time *
      </span> */}
    </div>
  </div>
</section>

        <br/>
        <br/>
        <br/>
        </React.Fragment>

    )
}

export default UpcomingEvents;