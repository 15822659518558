import './MinistryHero.css'
import { Typography, Button } from '@mui/material'
import React from 'react'
import health1 from '../../images/health1.webp'
import health2 from '../../images/health2.webp'
import health3 from '../../images/health3.webp'
import health4 from '../../images/health4.webp'
import health5 from '../../images/health5.webp'
import { useState, useEffect } from 'react'
import { Fade } from 'react-awesome-reveal'
const MinistryHero = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Add event listener to handle window resize
    window.addEventListener('resize', handleResize, { passive: true });

    // Call handleResize initially to set the initial state
    handleResize();

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize, { passive: true });
    };
  }, []);
  return (
    <React.Fragment>
    <div id='hero_container1'>
      <div className='bs1'>
    <div id="carouselExampleAutoplaying" class="carousel slide special_carousel" data-bs-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img src={health1} class="d-block w-100" alt="..."/>
    </div>
    <div class="carousel-item">
      <img src={health2} class="d-block w-100" alt="..."/>
    </div>
    <div class="carousel-item">
      <img src={health3} class="d-block w-100" alt="..."/>
    </div>
    <div class="carousel-item">
      <img src={health4} class="d-block w-100" alt="..."/>
    </div>
    <div class="carousel-item">
      <img src={health5} class="d-block w-100" alt="..."/>
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
      <div id='centerednew' >
      <Fade triggerOnce={true}  delay={200} >
      <Typography variant={isMobile ? 'h2' : 'h1'} sx={{ color: 'white', pb: 3, display: { xs: 'flex', sm: 'flex', md: 'flex', lg: 'flex', xl: 'flex' }, pt: 18 }} className='hero_title_text' style={{ fontFamily: 'Comfortaa' }}>Every Member is a MINISTER</Typography>
      <Typography variant='p' className="text-xl" sx={{ color: 'white',  display: { xs: 'flex', sm: 'flex', md: 'flex', lg: 'flex', xl: 'flex' } }} >"For we are his workmanship, created in Christ Jesus for good works, which God prepared beforehand, that we should walk in them."</Typography>
      <Button href='#min_sect' variant='outlined' size="large" sx={{ display: { xs: 'flex', sm: 'flex', md: 'flex', lg: 'flex', xl: 'flex' } ,mt:5, color: 'white', backgroundColor: 'orange', ":hover": {bgcolor: 'white', color: 'orange'}}}>Start Serving</Button>
      </Fade>
    </div>
   
    </div>
    </div>
    </React.Fragment>
  )
}

export default MinistryHero