import React from 'react';
import './App.css';
import Navbar from './components/Navbar/Navbar';
import Pastor from './pages/Pastor/Pastor';
import Home from './pages/Home/Home';
import Connect from './pages/Connect/Connect';
import Ministry from './pages/Ministry/Ministry';
import Schedule from './pages/Schedule/Schedule';
import Sermon from './pages/Sermon/Sermon';
import Prayer from './pages/Prayer/Prayer';
import Events from './pages/Events/Events';
import { Routes, Route} from 'react-router-dom';
import Give from './pages/Give/Give';
import Blog from './pages/Blog/Blog';
import Create from './pages/Blog/CRUD/Create';
import Read from './pages/Blog/CRUD/Read';
import Update from './pages/Blog/CRUD/Update';
import BlogView from './pages/Blog/BlogView';
import LoadingPage from './components/LoadingPage';
import NotFound from './pages/NotFound';
import Sitemap from './components/Sitemap';
import HoverNav from './components/Navbar/HoverNav';
import ChurchFooter from './components/ChurchInfoFooter/ChurchFooter';
import Contact from './pages/Contact';
import LatestSermons from './components/LatestSermons/LatestSermons';

function App() {

  const [isLoading, setIsLoading] = React.useState(true);
  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timeout);
  }, []);



  return (
    <React.Fragment>
      {isLoading ?( 
      <LoadingPage/>
      ) : (
        <React.Fragment>
      <HoverNav />
      <Routes>
        <Route exact path='/' element={<Home />} />
        <Route exact path='/pastor' element={<Pastor />} />
        <Route exact path='/connect' element={<Connect />} />
        <Route exact path='/ministries' element={<Ministry />} />
        <Route exact  path='/schedule' element={<Schedule />} />
        <Route path="/latest-sermons" component={LatestSermons} />
        <Route exact  path='/sermons' element={<Sermon/>} />
        <Route exact  path='/prayer' element={<Prayer/>} />
        <Route exact path='/events' element={<Events/>} />
        <Route exact  path='/give' element={<Give/>} />
        <Route exact  path='/blogs' element={<Blog/>} />
        <Route exact  path='/read' element={<Read/>} />
        <Route exact  path='/update' element={<Update/>} />
        <Route exact  path='/contact' element={<Contact/>} />
        <Route exact  path='/blog/id' element={<BlogView />} />
        <Route exact path='/create' component={Create} />
        <Route path='*' element={<NotFound/>} />
        <Route path="/sitemap.xml" element={<Sitemap />} />
      </Routes>
      <ChurchFooter />
        </React.Fragment>
      )}

      </React.Fragment>
  );
}

export default App;
