import React from 'react'
import { useState, useEffect } from 'react';
import { Typography, Button } from '@mui/material';
import pic from '../../images/blog2.webp';
import { useAuth0 } from '@auth0/auth0-react';
import CreateBlog from '../Blog/CRUD/Create'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Fade } from 'react-awesome-reveal';

const BlogHero = () => {
    const [isMobile, setIsMobile] = useState(false);
    const { isAuthenticated, user } = useAuth0();
    const [buttonName, setButtonName] = useState('Create');
    const [showCreateBlog, setCreateBlog] = useState(false)
    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
      };
  
      // Add event listener to handle window resize
      window.addEventListener('resize', handleResize, { passive: true });
  
      // Call handleResize initially to set the initial state
      handleResize();
  
      // Clean up the event listener on component unmount
      return () => {
        window.removeEventListener('resize', handleResize, { passive: true });
      };
    }, []);

    const Auth = ({ allowedEmails, children }) => {
        const { isAuthenticated, user } = useAuth0();
        const userEmail = user?.email;
      
        if (isAuthenticated && allowedEmails.includes(userEmail)) { 
            // console.log(user)
          return (
            <div>
              <h1 style={{color: 'white'}}>Welcome, {user.given_name}!</h1>
              <img src={user.picture} alt="User Profile" />
              <br/>
              <br/>
              <br/>
              <div className='lopl2' style={{color: 'white'}}>
        <ArrowDownwardIcon style={{fontSize: '64px'}} />
        </div>
              {children}
            </div>
          )
        }
      
        return null;
      };

      const handleCreateClick = () => {
        setCreateBlog(!showCreateBlog);
        setButtonName('Go Back');
      };
      
      const handleGoBackClick = () => {
        setCreateBlog(!showCreateBlog);
        setButtonName('Create');
      };

    return (
      <div id='hero_container1'>
        <div className='' >
        <img id='video' className='img-fluid' src={pic} alt='' />
        <div id='centerednew' >
        <Fade triggerOnce={true}  delay={200} >
          <Typography variant={isMobile ? 'h2' : 'h1'} sx={{ color: 'white', pb: 5, display: { xs: 'flex', sm: 'flex', md: 'flex', lg: 'flex', xl: 'flex' } }} className='hero_title_text' style={{ fontFamily: 'Comfortaa' }}>BLOGS</Typography>
          <Typography className='text-xl' variant='p' sx={{ color: 'white', pb: 5, display: { xs: 'flex', sm: 'flex', md: 'flex', lg: 'flex', xl: 'flex' } }}  style={{color: 'white'}}>"For I know the plans I have for you,” declares the Lord, “plans to prosper you and not to harm you, plans to give you hope and a future." - Jeremiah 29:11</Typography>
            <Auth allowedEmails={['enriquemgreene@gmail.com', 'endigoddess@gmail.com', 'apministries@gmail.com']}/>
            </Fade>
        </div>
    
        </div>
      </div>    
    )
}

export default BlogHero